import { FunctionComponent } from "react";

import { Carousel } from "@destination/components";
import { randUuid } from "@ngneat/falso";
import { ProductImages } from "@store/checkout/models/ProductImage";

interface Props {
  productImages: ProductImages
}

const ProductImageGallery: FunctionComponent<Props> = ({ productImages }) => {
  return (
    <div data-testid="product-images"> {productImages.imageUrls?.length > 0 && (
      <Carousel>
        {productImages.imageUrls.map((imageUrl, index) => {
          return <img key={`product-image-${randUuid()}`} data-testid={`product-image-${index}`}
            src={imageUrl} alt="product" className="h-48 w-96 object-contain" />
        })}
      </Carousel>
    )}
    </div>
  );
}

export default ProductImageGallery;