import axios, { AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";

import { paymentEndPoints } from "@api/PaymentEndpoints";
import { useUserContext } from "@hooks/useUserContext";
import { useGetOrganizationDetails } from "@organization/services/use-organization";
import { CreditCardDetail } from "@store/creditcard/models/CreditCardDetail";
import { PaymentCardRegistrationResponse } from "@store/payments/models/PaymentCardRegistrationResponse";

import { fetcher } from "@utils/swrUtils";

export function useGetPaymentDetails() {
  const { user, getAccessToken } = useUserContext();
  const { organization } = useGetOrganizationDetails();
  const baseSite = organization?.countryCode.toLowerCase() ?? undefined;

  const url = paymentEndPoints.getPaymentDetails(user.id, baseSite!);

  const { data, error, isLoading } = useSWR<
    { payments: CreditCardDetail[] } | undefined,
    Error
  >(url, (url: string) => fetcher(url, getAccessToken));
  return useMemo(
    () => ({ paymentDetails: data?.payments, isLoading, error }),
    [data, isLoading, error]
  );
}

export function usePaymentDetails() {
  const { user, getAccessToken } = useUserContext();
  const { organization } = useGetOrganizationDetails();
  const baseSite = organization?.countryCode.toLowerCase() ?? undefined;
  const { mutate } = useSWRConfig();

  const deletePaymentDetail = useCallback(
    async (paymentDetailsId: string) => {
      if (baseSite) {
        try {
          const accessToken = await getAccessToken();
          const config = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const url = paymentEndPoints.deletePaymentDetails(
            user.id,
            paymentDetailsId,
            baseSite
          );
          const result = await axios.delete(url, config).catch(() => {
            return new Error("Failed to delete the card");
          });
          await mutate(paymentEndPoints.getPaymentDetails(user.id, baseSite));
          return result;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return new Error(error.message);
          }
        }
      }
    },
    [baseSite, getAccessToken, mutate, user.id]
  );

  const getRegistrationSession = useCallback(async () => {
    if (baseSite) {
      try {
        const accessToken = await getAccessToken();
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const url = paymentEndPoints.getRegistrationUrl(baseSite, user.id);
        const response = await axios.get<
          PaymentCardRegistrationResponse,
          AxiosResponse<PaymentCardRegistrationResponse>
        >(url, config);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return new Error(error.message);
        }
      }
    }
  }, [baseSite, getAccessToken, user.id]);

  return useMemo(
    () => ({
      getRegistrationSession,
      deletePaymentDetail,
    }),
    [getRegistrationSession, deletePaymentDetail]
  );
}
