import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, useAlert } from "@destination/components";
import useDrawer from "@hooks/useDrawer";
import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import AddNewAddress from "./AddNewAddress";

const AddNewAddressButton: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });

  const { showDrawer, hideDrawer } = useDrawer();
  const { notify } = useAlert();

  const handleOnSuccess = () => {
    hideDrawer();
    notify({
      header: t("add.success.message"),
      variant: "success",
      isDismissible: true,
      timeout: 6000,
      "data-testid": "add-address-success-alert"
    });
  }
  const handleOnError = () => {
    hideDrawer();
    notify({
      header: t("add.error.message"),
      variant: "error",
      isDismissible: true,
      timeout: 6000,
      "data-testid": "add-address-error-alert"
    });
  }

  const handleAddAddress = () => {
    showDrawer({
      title: "",
      content: (
        <AddNewAddress onError={handleOnError} onSuccess={handleOnSuccess} />
      ),
    })
  }
  return (<Button data-testid="add-new-address-button" icon={<PlusIcon />}
    variant="primary" onClick={handleAddAddress}>
    {t('add.label')}
  </Button>);
}

export default AddNewAddressButton;