import { FunctionComponent, HTMLAttributeAnchorTarget } from "react";

import { NavLink } from "react-router-dom";

import { Card, Typography } from "@destination/components";

interface Props {
  icon: string;
  title: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  id: string;
}

const QuickAccessTile: FunctionComponent<Props> = ({
  href,
  title,
  icon: iconName,
  target,
  id,
}) => {
  return (
    <NavLink
      to={href}
      key={"nav_" + id}
      style={{ color: "black", textDecoration: "none" }}
      target={target}
      data-testid={id}
    >
      <Card className="grid grid-cols-1 gap-4 place-content-center place-items-center h-32 w-60" data-testid={`quick-access-tile-card-${id}`}>
        <img src={iconName} alt="check circle" data-testid="inActiveImage" />
        <Typography variant="smallTitle">{title}</Typography>
      </Card>
    </NavLink>
  );
};

export default QuickAccessTile;
