import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import { Checkbox, IOption, MultiSelect } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { useGetApplicationRoles } from "@users/applicationuser/services/ApplicationUserService";

interface Props {
  applicationId: string;
  applicationName: string;
  checked: boolean;
  isDisabled?: boolean;
  userRoles: string[];
  onAppRoleChange: (
    applicationId: string,
    applicationName: string,
    applicationRoles: string[],
    isValid: boolean
  ) => void;
  onAppRemove: () => void;
}

const EditApplicationAssociation: FunctionComponent<Props> = ({
  applicationId,
  applicationName,
  checked,
  isDisabled,
  userRoles,
  onAppRoleChange,
  onAppRemove,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "user.applications",
  });
  const { t: tApplicationRoles } = useTranslation(undefined, {
    keyPrefix: "application.roles",
  });
  const { t: tCommon } = useTranslation();

  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const { applicationRoles, limit, allowUpdate, isLoading, error } =
    useGetApplicationRoles(applicationId);

  const setValidState = useCallback(
    (
      isChecked: boolean,
      selectedOptions: IOption[],
      limit: number
    ): boolean => {
      if (!isChecked) {
        setIsValid(true);
        setErrorMessage(undefined);

        return true;
      }

      if (selectedOptions.length === 0) {
        setIsValid(false);
        const fieldName = t("role.label");
        const errorMessage = tCommon("validation.string.not-empty", {
          fieldName: fieldName,
        });
        setErrorMessage(errorMessage);

        return false;
      }

      if (limit > -1 && selectedOptions.length > limit) {
        setIsValid(false);
        const errorMessage = t("role.validation.max-roles", { limit: limit });
        setErrorMessage(errorMessage);

        return false;
      }

      setIsValid(true);
      setErrorMessage(undefined);

      return true;
    },
    [t, tCommon]
  );

  const setDisabledState = useCallback(
    (isChecked: boolean): void => {
      if (!isChecked) {
        setDisabled(true);
      } else {
        setDisabled(checked && !allowUpdate);
      }
    },
    [checked, allowUpdate]
  );

  const mapUserRoles = useCallback(() => {
    if (!checked) return [];

    const roles: IOption[] = [];
    if (userRoles.length > 0) {
      userRoles.forEach((role) => {
        roles.push({ label: role, value: role });
      });
    }
    return roles;
  }, [checked, userRoles]);

  // Map applicationRoles to options
  useEffect(() => {
    const roles: IOption[] = [];
    if (applicationRoles.length > 0) {
      applicationRoles.forEach((role: string) => {
        roles.push({ label: role, value: role });
      });
    }
    setOptions(roles);
  }, [applicationRoles]);

  // Map userRoles to selectedOptions
  useEffect(() => {
    setSelectedOptions(mapUserRoles);
  }, [mapUserRoles]);

  useEffect(() => {
    setValidState(isChecked, selectedOptions, limit);
    setDisabledState(isChecked);
  }, [isChecked, selectedOptions, limit, setValidState, setDisabledState]);

  function uniqueFilter(value: IOption, index: number, self: IOption[]) {
    return self.findIndex((item) => item.value === value.value) === index;
  }
  const handleAppRoleChange = (options: IOption[] | null) => {
    if (options === null) options = [];

    const uniqueOptions = options.filter(uniqueFilter);
    setSelectedOptions(uniqueOptions);
    const isValid = setValidState(isChecked, options, limit);
    setDisabledState(isChecked);

    if (options.length === 0) {
      onAppRoleChange(applicationId, applicationName, [], isValid);
      return;
    }
    if (limit > -1 && options.length > limit) {
      onAppRoleChange(
        applicationId,
        applicationName,
        options.map((item) => item.value) as string[],
        isValid
      );
      return;
    }

    onAppRoleChange(
      applicationId,
      applicationName,
      options.map((item) => item.value) as string[],
      isValid
    );
  };

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;

    setIsChecked(checkedValue);

    const roles = checkedValue ? mapUserRoles() : [];
    setSelectedOptions(roles);

    setValidState(checkedValue, roles, limit);
    setDisabledState(checkedValue);

    onAppRoleChange(
      applicationId,
      applicationName,
      roles.map((option) => option.value.toString()),
      !checkedValue || roles.length > 0
    );

    if (!checkedValue) onAppRemove();
  };

  if (isLoading)
    return <LoadingComponent message={tApplicationRoles("load.message")} />;
  if (error)
    return <ErrorComponent message={tApplicationRoles("load.error.message")} />;

  return (
    <div className="flex flex-col gap-2">
      <Checkbox
        data-testid={`edit-user-checkbox-${applicationId}`}
        label={applicationName}
        checked={isChecked}
        variant="alternative"
        disabled={(limit !== 0 && applicationRoles.length === 0) || isDisabled}
        onChange={handleCheckChange}
      />
      {limit !== 0 && applicationRoles.length === 0 && (
        <span>{tApplicationRoles("load.none.message")}</span>
      )}
      {limit !== 0 && applicationRoles.length > 0 && (
        <div data-testid={`edit-associated-applications-${applicationId}`}>
          <MultiSelect
            placeholder={tApplicationRoles("placeholder")}
            disabled={disabled || isDisabled}
            error={!isValid && !disabled}
            helperText={!isValid ? errorMessage : undefined}
            options={options}
            selected={selectedOptions.length === 0 ? null : selectedOptions}
            onChange={handleAppRoleChange}
          />
        </div>
      )}
    </div>
  );
};

EditApplicationAssociation.defaultProps = {
  isDisabled: false
}

export default EditApplicationAssociation;
