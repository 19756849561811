import { FunctionComponent } from "react";

import AccountMenu from "@account/components/AccountMenu";
import { Typography } from "@destination/components";
import { useUserContext } from "@hooks/useUserContext";
import "./UserProfile.css";


const UserProfile: FunctionComponent = () => {
  const { user } = useUserContext();

  return (
    <div className="flex flex-col profile-section" data-testid="user-profile">
      <Typography variant="sectionTitle" data-testid="user-name">{user.name}</Typography>
      <AccountMenu />
    </div>
  );
};

export default UserProfile;
