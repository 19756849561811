import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "@destination/components";

interface Props {
  message?: string;
}

const LoadingComponent: FunctionComponent<Props> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center">
      <LoadingIndicator>{message ?? t("load.message")}</LoadingIndicator>
    </div>
  );
};

export default LoadingComponent;
