import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";

import {
  formatUnknown,
  formatDate,
  formatNumber,
} from "@utils/i18n/formatters";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const isProduction = process.env.NODE_ENV === "production";

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init<HttpBackendOptions>({
    debug: !isProduction,
    returnNull: false,
    fallbackLng: "en",
    nonExplicitSupportedLngs: true,
    ns: "DS.CommonPlatformServices.CustomerPortal",
    backend: {
      loadPath: "/translations/{{ns}}.{{lng}}.json",
    },
    detection: {
      order: ["navigator"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value: unknown, format?: string, lng?: string): string => {
        if (!value) return "";

        if (!format) return formatUnknown(value);

        if (format.startsWith("date")) {
          return formatDate(value, format, lng);
        }
        if (format.startsWith("number")) {
          return formatNumber(value, format, lng);
        }

        return formatUnknown(value);
      },
    },
  });

export default i18n;
