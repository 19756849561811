import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";
import { Address } from "@store/checkout/models/Address";

interface Props {
  address: Address;
}

const AddressComponent: FunctionComponent<Props> = ({ address }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.checkout.address" });

  const renderTextWithTitle = (title: string, value: string) => {
    return <>
      <Typography variant="smallText">
        {title}
      </Typography>
      <Typography variant="smallText">
        {value}
      </Typography>
    </>
  }
  return (
    <div data-testid={`address-${address.id}`}>
      <div className="inline-grid grid-cols-2 gap-1">
        {renderTextWithTitle(t('street.label'), address.line1)}
        {renderTextWithTitle(t('city.label'), address.town)}
        {renderTextWithTitle(t('country.label'), address.country.name)}
        {address.state && (
          renderTextWithTitle(t('state.label'), address.state)
        )}
        {renderTextWithTitle(t('zipCode.label'), address.postalCode)}
      </div>
    </div>);
}

export default AddressComponent;