import { Typography } from "@destination/components";
import { FunctionComponent } from "react";

const Title: FunctionComponent = () => {

  return (
    <div
      className="grow text-center"
      data-testid="header-title"
    >
      <Typography variant="pageTitle">OMNIS portal</Typography>
    </div>
  );
};

export default Title;
