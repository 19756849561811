export class AssociatedApplicationRoles {
  readonly applicationId: string;
  readonly applicationName: string;
  readonly userRoles: string[];

  constructor(applicationId: string, applicationName: string, applicationRoles: string[]) {
    this.applicationId = applicationId;
    this.applicationName = applicationName;
    this.userRoles = applicationRoles;
  }
}
