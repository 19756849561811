import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import ProductName from "@store/products/components/ProductName";
import SubscriptionStatus from "@store/subscriptions/components/SubscriptionStatus";
import SubscriptionTextSection from "@store/subscriptions/components/SubscriptionTextSection";
import { useGetSubscriptionById } from "@store/subscriptions/services/SubscriptionService";
import SubscriptionDateSection from "./SubscriptionDateSection";
import CancelSubscriptionButton from "./CancelSubscriptionButton";

interface Props {
  subscriptionId: string
}

const SubscriptionDetail: FunctionComponent<Props> = ({ subscriptionId }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'store.subscription' });
  const { subscription, isLoading, error } = useGetSubscriptionById(subscriptionId);

  if (isLoading) return <LoadingComponent message={t("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  if (subscription) {
    return (
      <div data-testid={`subscription-detail-${subscriptionId}`}>
        <ProductName value={subscription.item.productName} />
        <SubscriptionDateSection id='created-date' value={new Date(subscription.createdDate)} />
        <SubscriptionStatus value={subscription.status} />
        <SubscriptionDateSection id='next-billing-date' value={new Date(subscription.nextBillingDate)} />
        <SubscriptionTextSection id='billing-cycle' value={t(`billing-cycle.${subscription.billingCycle}`)} />
        {subscription.status === "Active" && (
          <CancelSubscriptionButton subscriptionId={subscription.id} nextBillingDate={subscription.nextBillingDate} />
        )}

      </div>)
  }
}

export default SubscriptionDetail;