import { useCallback, useMemo } from "react";

import { useSWRConfig } from "swr";

import { putData } from "@hooks/usePutData";
import { useUserContext } from "@hooks/useUserContext";

import { SetDefaultOrganizationRequest } from "@account/models/SetDefaultOrganizationRequest";
import { userEndpoints } from "./endpoints";

export function useSetDefaultOrganization() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();

  const update = useCallback(
    async (organizationId: string) => {
      const url = userEndpoints.setDefaultOrganization();

      const request: SetDefaultOrganizationRequest = {
        organizationId: organizationId,
      };
      const result = await putData<SetDefaultOrganizationRequest>(
        getAccessToken,
        url,
        request
      );
      await mutate(userEndpoints.getDefaultOrganization());
      return result;
    },
    [getAccessToken, mutate]
  );

  return useMemo(() => ({ update }), [update]);
}
