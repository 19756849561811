import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, useAlert, useDialog } from "@destination/components";
import { ReactComponent as TrashIcon } from "@icons/trash.svg";
import { usePaymentDetails } from "@store/payments/services/PaymentService";

interface Props {
  paymentDetailId: string,
  cardHolderName: string
}

const DeletePaymentMethodButton: FunctionComponent<Props> = ({ paymentDetailId, cardHolderName }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.payment-methods" });
  const { t: tCommon } = useTranslation();
  const { notify } = useAlert();

  const { deletePaymentDetail } = usePaymentDetails();
  const { trigger } = useDialog();

  const handleButtonClick = () => {
    trigger({
      variant: "warning",
      header: t("confirm.title"),
      content: t("delete.confirm.message", { cardHolderName }),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: () => {
        deletePaymentDetail(paymentDetailId).then((response) => {
          if (response instanceof Error) {
            notify({
              header: t("delete.error.message"),
              variant: "error",
              isDismissible: true,
              timeout: 6000,
              "data-testid": "delete-payment-method-error"
            });
          }
          else {
            notify({
              header: t("delete.success.message"),
              variant: "success",
              isDismissible: true,
              timeout: 6000,
              "data-testid": "delete-payment-method-success"
            });
          }
        }).catch(() => {
          notify({
            header: t("delete.error.message"),
            variant: "error",
            isDismissible: true,
            timeout: 6000,
            "data-testid": "delete-payment-method-error"
          });
        }
        );
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        /* Do nothing */
      },
      "data-testid": "delete-payment-method"
    });
  }
  return (
    <IconButton data-testid={`delete-payment-method-button-${paymentDetailId}`} onClick={handleButtonClick}>
      <TrashIcon className="h-3 sm:h-4" />
    </IconButton>);
}

export default DeletePaymentMethodButton;