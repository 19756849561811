import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { Button, SearchInput, Table, TableDataCell, TableHeadCell } from "@destination/components";
import useDrawer from "@hooks/useDrawer";
import { useSearchQueryString } from "@hooks/useSearchQueryString";
import { useSortQueryString } from "@hooks/useSortQueryString";
import { useUserContext } from "@hooks/useUserContext";
import { Subscription } from "@store/subscriptions/models/Subscription";
import { filter, sort, useGetSubscriptions } from "@store/subscriptions/services/SubscriptionService";
import SubscriptionDetail from "./SubscriptionDetail";

const Subscriptions: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.subscriptions" });
  const { t: tSubscription } = useTranslation(undefined, { keyPrefix: "store.subscription" });

  const { activeSort, sortDirection, handleSort } = useSortQueryString();
  const { searchValue, handleSearch } = useSearchQueryString();

  const { user } = useUserContext();

  const { subscriptions, isLoading, error } = useGetSubscriptions();

  const [filteredSubscriptions, setFilteredSubscriptions] = useState<Subscription[] | undefined>();
  const [allSubscriptions, setAllSubscriptions] = useState<Subscription[] | undefined>();

  const { showDrawer } = useDrawer();

  const dateFormatter = new Intl.DateTimeFormat(user.language, {
    dateStyle: "short",
  });

  useEffect(() => {
    const subscriptionsFiltered = filter(subscriptions, searchValue);
    setFilteredSubscriptions(subscriptionsFiltered);
  }, [subscriptions, searchValue]);

  useEffect(() => {
    const sortedSubscriptions = sort(filteredSubscriptions, activeSort, sortDirection);
    setAllSubscriptions(sortedSubscriptions);
  }, [filteredSubscriptions, activeSort, sortDirection]);

  const productNameColumnName = "product-name";
  const createdDateColumnName = "created-date";
  const nextBillingDateColumnName = "next-billing-date";
  const statusColumnName = "status";

  function renderTableHeadCell(columnName: string) {
    return (
      <TableHeadCell
        sortActive={activeSort === columnName}
        sortDirection={sortDirection}
        onSort={() => handleSort(columnName)}
      >
        <div data-testid={`${columnName}-column`}>
          {tSubscription(`${columnName}.column-header`)}
        </div>
      </TableHeadCell>
    );
  }
  const renderAColumn = (id: string, value: string) => {
    return <TableDataCell data-testid={id}>
      {value}
    </TableDataCell>;
  };
  const renderActionColumn = (id: string) => {
    return <TableDataCell data-testid={id}>
      <Button
        variant="discreet"
        data-testid={`${id}-more-details`}
        onClick={() => handleClickMoreDetails(id)}
      >
        {tSubscription("more-details")}
      </Button>
    </TableDataCell>;
  };

  const renderDateColumn = (id: string, date: Date) => {
    return (
      <TableDataCell data-testid={id}>
        {dateFormatter.format(new Date(date))}
      </TableDataCell>
    );
  };

  const handleClickMoreDetails = (subscriptionId: string) => {
    showDrawer({
      title: "",
      content: (
        <SubscriptionDetail subscriptionId={subscriptionId} />
      ),
    })
  }
  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error)
    return <ErrorComponent message={t("load.error.message")} />;
  if (subscriptions?.length === 0)
    return <ErrorComponent message={t("load.none.message")} />;

  return (
    <div data-testid="subscriptions-table-container">
      <div className="flex flex-row-reverse w-full">
        <div className="w-[;500px] mb-3">
          <SearchInput
            data-testid="search-subscriptions"
            value={searchValue}
            placeholder={t("search.placeholder")}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div data-testid="subscriptions-table">
        <Table className="w-full" >
          <thead>
            <tr className="leading-[0px]" data-testid="thead">
              {renderTableHeadCell(productNameColumnName)}
              {renderTableHeadCell(createdDateColumnName)}
              {renderTableHeadCell(nextBillingDateColumnName)}
              {renderTableHeadCell(statusColumnName)}
              <TableHeadCell>
                <div data-testid="actions-column">
                  {tSubscription("actions.column-header")}
                </div>
              </TableHeadCell>
            </tr>
          </thead>

          <tbody data-testid="tbody">
            {allSubscriptions?.map((subscription) => (
              <tr key={subscription.id}>
                {renderAColumn(`${subscription.id}-${productNameColumnName}-column-value`, subscription.item.productName)}
                {renderDateColumn(`${subscription.id}-${createdDateColumnName}-column-value`, subscription.createdDate)}
                {renderDateColumn(`${subscription.id}-${nextBillingDateColumnName}-column-value`, subscription.nextBillingDate)}
                {renderAColumn(`${subscription.id}-${statusColumnName}-column-value`, subscription.status)}
                {renderActionColumn(subscription.id)}
              </tr>
            ))}
          </tbody>
          <tfoot data-testid="tfoot">
            <tr>
              <td colSpan={6} className="pt-3">
                {t("count.message", { count: filteredSubscriptions?.length })}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
}

export default Subscriptions;