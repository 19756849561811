import { useMemo } from "react";

import useSWR from "swr";

import { useUserContext } from "@hooks/useUserContext";
import { fetcher } from "@utils/swrUtils";

import { userEndpoints } from "./endpoints";
import { DefaultOrganizationResponse } from "@account/models/DefaultOrganizationResponse";

export function useGetDefaultOrganization() {
  const { getAccessToken } = useUserContext();
  const url = userEndpoints.getDefaultOrganization();

  const { data, error, isLoading } = useSWR<DefaultOrganizationResponse, Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({ defaultOrganizationId: data?.organizationId, isLoading, error }),
    [data, isLoading, error]
  );
}
