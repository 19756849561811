import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

import "./PageTitle.css";
import PageHelmet from "./PageHelmet";

interface Props {
  title: string;
  helmetTitle?: string;
}

const PageTitle: FunctionComponent<Props> = ({ title, helmetTitle }) => {
  return (
    <>
      <PageHelmet title={helmetTitle ?? title} />
      <Typography
        variant="pageTitle"
        className="page-title"
        data-testid="page-title"
      >
        {title}
      </Typography>
    </>
  );
};

export default PageTitle;
