import { FunctionComponent } from "react";

import { Button } from "@destination/components";
import useDrawer from "@hooks/useDrawer";
import UserIcon from "@icons/user.svg";
import { useTranslation } from "react-i18next";
import EditApplicationUser from "./EditApplicationUser";
import { useUserContext } from "@hooks/useUserContext";

const InviteApplicationUserButton: FunctionComponent = () => {

  const { user } = useUserContext();
  const { t } = useTranslation(undefined, { keyPrefix: "application.users" });

  const { showDrawer, hideDrawer } = useDrawer();

  const editApplicationUser = () => {
    showDrawer({
      title: t("invite.title"),
      content: (
        <EditApplicationUser
          onSuccess={() => {
            hideDrawer();
          }}
          onCancel={hideDrawer} />
      ),
    });
  };
  if (!user.isAdministrator) return "";
  return (
    <div data-testid="invite-application-user-button">
      <Button
        icon={
          <img
            className="h-3 sm:h-4"
            src={UserIcon}
            alt="check circle"
            data-testid="inActiveImage"
          />
        }
        variant="primary"
        onClick={editApplicationUser}
      >
        {t("invite.label")}
      </Button>
    </div>
  );
};

export default InviteApplicationUserButton;
