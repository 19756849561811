import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";
import ProductContent from "./ProductContent";

interface Props {
  searchValue: string;
}

const AllProducts: FunctionComponent<Props> = ({ searchValue }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.all-products" });
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const expandableCardClasses =
    "items-center hover:cursor-pointer pt-5 shadow-transparent";

  const renderCardContent = (
    <div className="flex flew-row flex-wrap gap-10" data-testid={`all-product-cards`}>
      <Card className="shadow-transparent" >
        <ProductContent searchValue={searchValue} />
      </Card>
    </div>
  );

  return (
    <div data-testid="all-products" className="[&>div>div]:!overflow-visible">
      <Card className={expandableCardClasses}
        expandableCardContent={renderCardContent}
        isExpanded={isExpanded}
        onExpand={() => setIsExpanded(true)}
        onCollapse={() => setIsExpanded(false)} >
        <Typography className="mb-3" variant="sectionTitle">
          {t("label")}
        </Typography>
      </Card>
    </div>
  );
}

export default AllProducts;