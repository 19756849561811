import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

interface Props {
  title: string;
}

const SectionTitle: FunctionComponent<Props> = ({ title }) => {
  return (
    <Typography className="mb-3 mt-10" variant="sectionTitle">
      {title}
    </Typography>
  );
};

export default SectionTitle;
