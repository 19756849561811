function extractJson(format: string, specifier: string) {
  return format.trim().replace(specifier, "").replace("(", "").replace(")", "");
}

export function toOptions<T>(format: string): T | undefined {
  const specifier = "date";
  if (format.trim() === specifier) {
    return <T>{};
  }

  try {
    const json = extractJson(format, specifier);
    return <T>JSON.parse(json);
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
