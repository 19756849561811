import { useMemo } from "react";

import useSWR from "swr";

import { applicationEndpoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { fetcher } from "@utils/swrUtils";
import { OrganizationApplicationsResponse } from "@organization/models/OrganizationApplicationsResponse";

export function useGetAssociatedApplications() {
  const { getAccessToken } = useUserContext();
  const url = applicationEndpoints.organizationApplications();

  const { data, error, isLoading } = useSWR<
    OrganizationApplicationsResponse,
    Error
  >(url, (url: string) => fetcher(url, getAccessToken));

  return useMemo(
    () => ({ applications: data?.applications, isLoading, error }),
    [data, isLoading, error]
  );
}
