import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Button,
  Tooltip,
  Typography,
  useAlert,
  useDialog,
} from "@destination/components";

import { UserOrganization } from "@account/models/UserOrganization";
import { useOrganizationContext } from "@hooks/useOrganizationContext";
import { useUserContext } from "@hooks/useUserContext";
import DefaultOrganizationToggleButton from "./DefaultOrganizationToggleButton";

interface Props {
  organizations: UserOrganization[];
}
const AccountMenuItems: FunctionComponent<Props> = ({ organizations }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization" });
  const { t: tCommon } = useTranslation();

  const { switchOrganization } = useUserContext();
  const { organization: currentOrganization } = useOrganizationContext();

  const { trigger } = useDialog();
  const { notify } = useAlert();

  const [showAll, setShowAll] = useState<boolean>(false);

  const [organizationsShown, setOrganizationsShown] = useState<UserOrganization[]>(
    []
  );

  useEffect(() => {
    const sortedOrganizations = [...organizations].sort(
      (a: UserOrganization, b: UserOrganization): number => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    );

    if (showAll) setOrganizationsShown(sortedOrganizations);
    else setOrganizationsShown(sortedOrganizations.slice(0, 3));
  }, [organizations, showAll]);

  function onSwitchOrganization(organization: UserOrganization) {
    trigger({
      variant: "warning",
      header: t("switch.confirm.title"),
      content: t("switch.confirm.message", { organization: organization.name }),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: () => {
        switchOrganization(organization.id).catch(() => {
          notify({
            header: t("switch.error.message", { organization: organization.name }),
            variant: "error",
            isDismissible: true,
            timeout: 6000,
            "data-testid": "switch-organization-error"
          });
        }
        );
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        /* Do nothing */
      },
      "data-testid": "switch-organization"
    });
  }

  return (
    <>
      {organizationsShown.map(
        (organization) =>
          organization.id !== currentOrganization.id && (
            <li
              key={organization.id}
              data-testid={`listItem-${organization.id}`}
              className="flex flex-row gap-2 group/item hover:bg-slate-100 py-1  bg-white cursor-pointer"
            >
              <div className="w-4 hover:min-w-0"></div>
              <div className="grow">
                <Tooltip
                  message={t("switch.tooltip", {
                    organization: organization.name,
                  })}
                  placement="bottom-left"
                >
                  <button
                    data-testid={`switch-org-${organization.id}`}
                    className="w-full text-left"
                    onClick={() => onSwitchOrganization(organization)}
                  >
                    <Typography variant="description">
                      {organization.name}
                    </Typography>
                  </button>
                </Tooltip>
              </div>
              <DefaultOrganizationToggleButton organization={organization} />
            </li>
          )
      )}
      {organizations.length > 3 && (
        <li className="flex flex-col items-center">
          {showAll && (
            <Button
              variant="discreet"
              data-testid="show-less"
              className="cursor-pointer"
              onClick={() => setShowAll((flag) => !flag)}
            >
              {t("show", { context: "less" })}
            </Button>
          )}
          {!showAll && (
            <Button
              variant="discreet"
              data-testid="show-more"
              className="cursor-pointer"
              onClick={() => setShowAll((flag) => !flag)}
            >
              {t("show", { context: "more" })}
            </Button>
          )}
        </li>
      )}
    </>
  );
};

export default AccountMenuItems;
