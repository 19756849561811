import { Typography } from "@destination/components";
import { FunctionComponent } from "react";

interface Props {
  value: string;
}

const ProductName: FunctionComponent<Props> = ({ value }) => {
  return (
    <div className="mt-4 text-wrap">
      <Typography
        variant="description"
        className="font-bold"
        data-testid="product-name-value"
      >
        {value}
      </Typography>
    </div>
  );
};

export default ProductName;
