
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, useAlert, useDialog } from "@destination/components";
import useDrawer from "@hooks/useDrawer";
import { useCancelSubscription } from "@store/subscriptions/services/SubscriptionService";
import SubscriptionCancelledMessage from "./SubscriptionCancelledMessage";

interface Props {
  subscriptionId: string;
  nextBillingDate: Date;
}

const CancelSubscriptionButton: FunctionComponent<Props> = ({ subscriptionId, nextBillingDate }) => {

  const { t } = useTranslation(undefined, { keyPrefix: 'store.subscription.cancel' });

  const { trigger } = useDialog();
  const { notify } = useAlert();
  const [isBusy, setIsBusy] = useState(false);

  const { cancelSubscription } = useCancelSubscription();

  const { showDrawer, hideDrawer } = useDrawer();

  const handleButtonClick = () => {
    setIsBusy(true);
    trigger({
      variant: "warning",
      header: t("confirm.title"),
      content: t("confirm.message", { nextBillingDate: nextBillingDate }),
      confirmButton: t("button.confirm.label"),
      onConfirm: async () => {
        const { error } = await cancelSubscription(subscriptionId);
        if (error) {
          notify({
            header: t("error.message"),
            variant: "error",
            isDismissible: true,
            timeout: 6000,
            "data-testid": "cancel-subscription-error"
          });
          setIsBusy(false);
        }
        else {
          showDrawer({
            title: "",
            content: (
              <SubscriptionCancelledMessage nextBillingDate={nextBillingDate} onFinish={() => hideDrawer()} />
            ),
          })
        }
      },
      cancelButton: t("button.keep-plan.label"),
      onCancel: () => {
        /* Do nothing */
        setIsBusy(false);
      },
      "data-testid": "cancel-subscription"
    });
  }

  return (
    <div className="flex flex-row-reverse gap-4 pl-2 pt-4">
      <Button data-testid="cancel-subscription-button" isLoading={isBusy} variant="accent" onClick={handleButtonClick}>
        {t("button.label")}
      </Button>
    </div>);
}

export default CancelSubscriptionButton;