import axios, { AxiosError } from "axios";

export const fetcher = async (
  url: string,
  getAccessToken: () => Promise<string>
) => {
  const accessToken = await getAccessToken();

  const request = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await axios
    .get(url, request)
    .catch(function (error: AxiosError) {
      throw new Error(error.message);
    });

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  if (response.data?.errorMessage) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw new Error(response.data.errorMessage);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data;
};
