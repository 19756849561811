import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import { Card, ITab, TabMenu } from "@destination/components";

import { useTabIndex } from "@hooks/useTabIndex";

const OrganizationTabMenu: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization" });
  const tabs: ITab[] = [
    {
      id: "organization",
      label: t("organization_tab.label"),
      content: (
        <Card className="rounded-tl-none" data-testid="tab-Organization">
          <Outlet />
        </Card>
      ),
    },
    {
      id: "users",
      label: t("users_tab.label"),
      content: (
        <Card className="rounded-tl-none" data-testid="tab-Users">
          <Outlet />
        </Card>
      ),
    },
  ];

  const { currentTabIndex } = useTabIndex(tabs);
  const navigate = useNavigate();
  const handleChangeTab = (tabIndex: number) => {
    if (tabIndex === 0) {
      return navigate("");
    }

    navigate(tabs[tabIndex].id.toLowerCase());
  };

  return (
    <TabMenu value={currentTabIndex} onChange={handleChangeTab} tabs={tabs} />
  );
};

export default OrganizationTabMenu;
