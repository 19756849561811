import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, useAlert } from "@destination/components";

import { Application } from "@applications/models/Application";
import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { AssociatedApplicationRoles } from "@users/organizationuser/models/AssociatedApplicationRoles";

import {
  useInviteApplicationUser,
  useRemoveUserApplication,
  useUserApplications,
} from "../services/ApplicationUserService";
import EditApplicationAssociations from "./EditApplicationAssociations";

interface Props {
  userId: string;
  userDisplayName: string;
  onCancel: () => void;
  onSuccess: () => void;
}

function areRolesEqual(orgRoles: string[], newRoles: string[]): boolean {
  if (orgRoles.length !== newRoles.length) return false;

  for (const role of orgRoles) {
    const index = newRoles.findIndex((item) => item === role);
    if (index === -1) return false;
  }

  return true;
}

const EditApplicationAssociationsForm: FunctionComponent<Props> = ({
  userId,
  userDisplayName,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "application.users",
  });
  const { t: tApplications } = useTranslation(undefined, {
    keyPrefix: "user.applications",
  });
  const { t: tCommon } = useTranslation();

  const { notify } = useAlert();

  const [selectedApplications, setSelectedApplications] = useState<
    Array<{
      applicationAssociation: AssociatedApplicationRoles;
      isValid: boolean;
    }>
  >([]);
  const [canSave, setCanSave] = useState<boolean>(false);

  const { invite: inviteApplicationUser } = useInviteApplicationUser();
  const { deleteApp: deleteApplicationUser } = useRemoveUserApplication();

  const { userApplications, isLoading, error } = useUserApplications(userId);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    if (!userApplications) return;

    const items: Array<{
      applicationAssociation: AssociatedApplicationRoles;
      isValid: boolean;
    }> = [];
    userApplications?.forEach((item) => {
      items.push({
        applicationAssociation: {
          applicationId: item.application.id,
          applicationName: item.application.name,
          userRoles: item.userRoles,
        },
        isValid: true,
      });
    });

    setSelectedApplications(items);
  }, [userApplications]);

  useEffect(() => {
    setCanSave(selectedApplications.every((item) => item.isValid));
  }, [selectedApplications]);

  function handleAppRemove(application: Application) {
    setSelectedApplications((current) => {
      const applications = [...current];

      const index = applications.findIndex(
        (item) => item.applicationAssociation.applicationId === application.id
      );
      if (index > -1) applications.splice(index, 1);

      return applications;
    });
  }

  function handleAppRoleChange(
    applicationId: string,
    applicationName: string,
    applicationRoles: string[],
    isValid: boolean
  ) {
    const selectedAppRole = new AssociatedApplicationRoles(
      applicationId,
      applicationName,
      applicationRoles
    );

    setSelectedApplications((current) => {
      const applications = [...current];

      const index = applications.findIndex(
        (item) => item.applicationAssociation.applicationId === applicationId
      );
      if (index > -1) applications.splice(index, 1);

      applications.push({
        applicationAssociation: selectedAppRole,
        isValid: isValid,
      });

      return applications;
    });
  }

  const saveChanges = () => {
    setIsBusy(true);
    updateApplicationAssociationsIfNeeded()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        notify({
          header: t("save.error.message"),
          variant: "error",
          isDismissible: true,
          timeout: 6000,
          "data-testid": "edit-application-user-save-error"
        });
      })
      .finally(() => {
        setIsBusy(false);
      })
  };

  const updateApplicationAssociationsIfNeeded = async () => {
    for (const applicationAssociation of selectedApplications) {
      const orgApplicationAssociation = userApplications!.find(
        (item) =>
          item.application.id ===
          applicationAssociation.applicationAssociation.applicationId
      );
      if (!orgApplicationAssociation) {
        await addApplicationAssociation(
          applicationAssociation.applicationAssociation
        );
      } else if (
        !areRolesEqual(
          orgApplicationAssociation.userRoles,
          applicationAssociation.applicationAssociation.userRoles
        )
      ) {
        await updateApplicationAssociation(
          applicationAssociation.applicationAssociation
        );
      }
    }

    // Remove Application Associations that are no longer in the selected applications
    for (const applicationAssociation of userApplications!) {
      const index = selectedApplications.findIndex(
        (item) => item.applicationAssociation.applicationId === applicationAssociation.application.id
      );
      if (index > -1) continue;

      await removeApplicationAssociation(
        applicationAssociation.application,
        userId
      );
    }
  };

  const addApplicationAssociation = async (
    applicationAssociation: AssociatedApplicationRoles
  ) => {
    const { error } = await inviteApplicationUser(
      applicationAssociation.applicationId,
      userId,
      applicationAssociation.userRoles
    );
    if (error) {
      notify({
        header: t("add.error.message", {
          user: userDisplayName,
          application: applicationAssociation.applicationName,
        }),
        variant: "error",
        isDismissible: true,
        timeout: 6000,
        "data-testid": "associate-application-user-save-error"
      });
    } else {
      notify({
        header: t("add.success.message", {
          user: userDisplayName,
          application: applicationAssociation.applicationName,
        }),
        variant: "success",
        isDismissible: true,
        timeout: 6000,
        "data-testid": "associate-application-user-save-success"
      });
    }
  };

  const updateApplicationAssociation = async (
    applicationAssociation: AssociatedApplicationRoles
  ) => {
    const { error } = await inviteApplicationUser(
      applicationAssociation.applicationId,
      userId,
      applicationAssociation.userRoles
    );
    if (error) {
      notify({
        header: t("update.error.message", {
          user: userDisplayName,
          application: applicationAssociation.applicationName,
        }),
        variant: "error",
        isDismissible: true,
        timeout: 6000,
        "data-testid": "update-application-user-save-error"
      });
    } else {
      notify({
        header: t("update.success.message", {
          user: userDisplayName,
          application: applicationAssociation.applicationName,
        }),
        variant: "success",
        isDismissible: true,
        timeout: 6000,
        "data-testid": "update-application-user-save-error"
      });
    }
  };

  const removeApplicationAssociation = async (
    application: Application,
    userId: string
  ) => {
    const { error } = await deleteApplicationUser(application.id, userId);
    if (error) {
      notify({
        header: t("delete.error.message", {
          user: userDisplayName,
          application: application.name,
        }),
        variant: "error",
        isDismissible: true,
        timeout: 6000,
        "data-testid": "delete-application-user-save-error"
      });
    } else {
      notify({
        header: t("delete.success.message", {
          user: userDisplayName,
          application: application.name,
        }),
        variant: "success",
        isDismissible: true,
        timeout: 6000,
        "data-testid": "delete-application-user-save-success"
      });
    }
  };

  if (isLoading) return <LoadingComponent message={tApplications("load.message")} />;
  if (error)
    return <ErrorComponent message={tApplications("load.error.message")} />;

  return (
    <>
      <EditApplicationAssociations
        onAppRoleChange={handleAppRoleChange}
        onAppRemove={handleAppRemove}
        userId={userId}
        isDisabled={isBusy}
        userApplications={userApplications!}
      />
      <div className="flex flex-row-reverse gap-4">
        <Button variant="primary" data-testid="save" isLoading={isBusy} disabled={!canSave} onClick={saveChanges}>
          {tCommon("button.save.label")}
        </Button>
        <Button disabled={isBusy} data-testid="cancel" onClick={() => onCancel()}>
          {tCommon("button.cancel.label")}
        </Button>
      </div>
    </>
  );
};

export default EditApplicationAssociationsForm;
