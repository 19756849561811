import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import { Card, ITab, TabMenu } from "@destination/components";

import { useTabIndex } from "@hooks/useTabIndex";

const StoreTabMenu: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });
  const tabs: ITab[] = [
    {
      id: "products",
      label: t("products_tab.label"),
      content: (
        <Card className="rounded-tl-none" data-testid="tab-Products">
          <Outlet />
        </Card>
      ),
    },
    {
      id: "purchases",
      label: t("purchases_tab.label"),
      content: (
        <Card className="rounded-tl-none" data-testid="tab-Purchases">
          <Outlet />
        </Card>
      ),
    },
    {
      id: "subscriptions",
      label: t("subscriptions_tab.label"),
      content: (
        <Card className="rounded-tl-none" data-testid="tab-Subscriptions">
          <Outlet />
        </Card>
      ),
    },
    {
      id: "account",
      label: t("account_tab.label"),
      content: (
        <Card className="rounded-tl-none" data-testid="tab-account">
          <Outlet />
        </Card>
      ),
    },
  ];

  const { currentTabIndex } = useTabIndex(tabs);
  const navigate = useNavigate();
  const handleChangeTab = (tabIndex: number) => {
    if (tabIndex === 0) {
      return navigate("");
    }

    navigate(tabs[tabIndex].id.toLowerCase());
  };

  return (
    <div data-testid="store-tabs">
      <TabMenu value={currentTabIndex} onChange={handleChangeTab} tabs={tabs} />
    </div>
  );
};

export default StoreTabMenu;
