import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Checkbox,
  SearchInput,
  Table,
  TableDataCell,
  TableHeadCell,
  Typography,
} from "@destination/components";

import { Application } from "@applications/models/Application";
import { ApplicationUser } from "@applications/models/ApplicationUser";
import {
  filter,
  sort,
  useGetApplicationsUsers,
} from "@applications/services/ApplicationUserService";
import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import DeleteApplicationUserButton from "@users/applicationuser/components/DeleteApplicationUserButton";
import EditApplicationUserButton from "@users/applicationuser/components/EditApplicationUserButton";
import UserStatusIcon from "@users/components/UserStatusIcon";
import { useUserContext } from "@hooks/useUserContext";
import { useSortQueryString } from "@hooks/useSortQueryString";
import { useSearchQueryString } from "@hooks/useSearchQueryString";

interface Props {
  application: Application;
}

const ApplicationUsers: FunctionComponent<Props> = ({ application }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "applications.users" });
  const { t: tUser } = useTranslation(undefined, { keyPrefix: "user" });
  const { t: tCommon } = useTranslation();

  const { user: currentUser } = useUserContext();

  const [filteredUsers, setFilteredUsers] = useState<ApplicationUser[] | undefined>();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedUsers, setCheckedUsers] = useState<ApplicationUser[]>([]);

  const { activeSort, sortDirection, handleSort } = useSortQueryString(application.id);
  const { searchValue, handleSearch } = useSearchQueryString(application.id);


  const [users, setUsers] = useState<ApplicationUser[] | undefined>();

  const { applicationUsers, isLoading, error } = useGetApplicationsUsers(
    application.id
  );

  const [allowSelection, setAllowSelection] = useState<boolean>(false);

  useEffect(() => {
    setAllowSelection(currentUser.isAdministrator);
  }, [currentUser]);

  useEffect(() => {
    const users = filter(applicationUsers, searchValue);
    setFilteredUsers(users);
  }, [application.id, applicationUsers, searchValue]);

  useEffect(() => {
    const users = sort(filteredUsers, activeSort, sortDirection);
    setUsers(users);
  }, [filteredUsers, activeSort, sortDirection]);

  useEffect(() => {
    const checkedRows: ApplicationUser[] = [];
    if (users && allowSelection) {
      users.forEach((user) => {
        const checkBox: HTMLInputElement = document.getElementById(
          `${application.id}_${user.userId}`
        ) as HTMLInputElement;
        checkBox.checked = checkedAll;
        if (checkedAll === true) {
          checkedRows.push(user);
        }
      });
    }
    setCheckedUsers(checkedRows);
    // users is intentialy kept out of the dependencies because we handle it in a specific useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedAll, allowSelection]);

  useEffect(() => {
    setCheckedUsers((current) => {
      const checkedRows: ApplicationUser[] = [];
      if (current) {
        current.forEach((checkedUser) => {
          const userFound = users?.find(
            (user) => user.userId === checkedUser.userId
          );
          if (userFound) checkedRows.push(checkedUser);
        });
      }
      return checkedRows;
    });
  }, [users]);

  function handleToggleSelectAll(event: ChangeEvent<HTMLInputElement>): void {
    setCheckedAll(event.target.checked);
  }

  function handleToggleSelectRow(user: ApplicationUser): void {
    setCheckedUsers(
      checkedUsers.includes(user)
        ? checkedUsers.filter((el) => el !== user)
        : [...checkedUsers, user]
    );
  }

  const renderAColumn = (userId: string, columnName: string, value: string) => {
    return <TableDataCell><div data-testid={`application-${application.id}-user-${userId}-${columnName}`}>{value}</div></TableDataCell>;
  };

  const renderStatusColumn = (userId: string, state: string) => {
    return (
      <TableDataCell>
        <div className="flex gap-2" data-testid={`application-${application.id}-user-${userId}-status`}>
          <UserStatusIcon state={state} />
          {state}
        </div>
      </TableDataCell>
    );
  };

  const renderSelectionColumn = (user: ApplicationUser) => {
    if (!allowSelection) return "";
    return (
      <TableDataCell className="w-1">
        <div data-testid={user.userId}>
          <Checkbox
            id={application.id + "_" + user.userId}
            data-testid={`checkbox-${application.id}-${user.userId}`}
            variant="alternative"
            label={undefined}
            onChange={() => handleToggleSelectRow(user)}
          />
        </div>
      </TableDataCell>
    );
  };

  if (isLoading) return <LoadingComponent message={t("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (
    <div data-testid={`applicationUsers-${application.id}`} >
      {
        checkedUsers.length > 0 ? (
          <div
            className="float-left flex items-center gap-1"
            data-testid="delete-users"
          >
            <Typography variant="smallTitle">
              {t("selected.message", { count: checkedUsers.length })}
            </Typography>
            <DeleteApplicationUserButton
              users={checkedUsers}
              application={application}
            />
          </div>
        ) : (
          ""
        )
      }

      < div className="w-[500px] float-right pr-10 pb-5" >
        {filteredUsers && (
          <SearchInput
            data-testid={`search-application-user-${application.id}`}
            value={searchValue}
            placeholder={t("search.placeholder")}
            onChange={handleSearch}
          />
        )
        }
      </div >
      <Table className="w-full">
        <thead>
          <tr className="leading-[0px]" data-testid="thead">
            {allowSelection && (
              <TableHeadCell>
                <Checkbox
                  id="checkAll"
                  data-testid="checkAll"
                  variant="alternative"
                  checked={checkedAll}
                  label={undefined}
                  onChange={handleToggleSelectAll}
                />
              </TableHeadCell>
            )}
            <TableHeadCell
              sortActive={activeSort === "name"}
              sortDirection={sortDirection}
              onSort={() => handleSort("name")}
            >
              <div data-testid="name-column">{tUser("display-name.column-header")}</div>
            </TableHeadCell>
            <TableHeadCell
              sortActive={activeSort === "email"}
              sortDirection={sortDirection}
              onSort={() => handleSort("email")}
            >
              <div data-testid="email-column">{tUser("email.column-header")}</div>
            </TableHeadCell>
            <TableHeadCell
              sortActive={activeSort === "role"}
              sortDirection={sortDirection}
              onSort={() => handleSort("role")}
            >
              <div data-testid="role-column">{tUser("role.column-header")}</div>
            </TableHeadCell>
            <TableHeadCell
              sortActive={activeSort === "status"}
              sortDirection={sortDirection}
              onSort={() => handleSort("status")}
            >
              <div data-testid="status-column">{tUser("status.column-header")}</div>
            </TableHeadCell>

            <TableHeadCell> <div data-testid="actions-column">{tCommon("actions.column-header")}</div></TableHeadCell>
          </tr>
        </thead>
        <tbody data-testid="tbody">
          {users?.map((associatedUser) => (
            <tr
              key={`applicationusers_${application.id}_${associatedUser.userId}`}
            >
              {allowSelection && renderSelectionColumn(associatedUser)}
              {renderAColumn(associatedUser.userId, 'displayName', associatedUser.displayName)}
              {renderAColumn(associatedUser.userId, 'email', associatedUser.email)}
              {renderAColumn(associatedUser.userId, 'roles', associatedUser.applicationRoles.join(", "))}
              {renderStatusColumn(associatedUser.userId, associatedUser.state)}

              <TableDataCell>
                <div className="grid grid-cols-2">
                  <EditApplicationUserButton applicationId={application.id} user={associatedUser} />
                </div>
              </TableDataCell>
            </tr>
          ))}
        </tbody>
        <tfoot data-testid="tfoot">
          <tr>
            <td colSpan={3} className="pt-3">
              {t("count.message", { count: filteredUsers?.length })}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div >
  );
};

export default ApplicationUsers;
