import { useMemo } from "react";

import useSWR from "swr";

import { addressEndPoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { Address } from "@store/addresses/models/Address";
import { fetcher } from "@utils/swrUtils";

export function useGetAddresses() {
  const { getAccessToken } = useUserContext();
  const url = addressEndPoints.getAddresses();

  const { data, error, isLoading } = useSWR<{ addresses: Address[] }, Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );
  return useMemo(
    () => ({ addresses: data?.addresses, isLoading, error }),
    [data, isLoading, error]
  );
}
