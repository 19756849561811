import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import PageHelmet from "@components/PageHelmet";
import { Typography } from "@destination/components";
import { ReactComponent as InfoIcon } from "@icons/info_circle_16.svg";
import AddPaymentMethodButton from "@store/payments/components/AddPaymentMethodButton";
import PaymentDetail from "@store/payments/components/PaymentDetail";
import { useGetPaymentDetails } from "@store/payments/services/PaymentService";

const PaymentDetails: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.payment-methods" });

  const { paymentDetails, isLoading, error } = useGetPaymentDetails();

  if (isLoading) return <LoadingComponent message={t("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (<div className="flex">
    <PageHelmet
      title={`${t("page_title")}`}
    />
    <div className="grow w-full">
      <div className="m-2">
        {(!paymentDetails || paymentDetails?.length === 0) && (
          <ErrorComponent message={t("load.none.message")} />
        )}
      </div>
      <div className="flex flex-wrap">
        {paymentDetails?.map((paymentDetail) => {
          return (
            <PaymentDetail key={paymentDetail.id} paymentDetail={paymentDetail} />
          )
        })

        }
      </div>
      <div className="flex gap-2" data-testid="info-section">
        <InfoIcon />
        <Typography variant="description">{t("info.message")}</Typography>
      </div>
    </div>
    <div className="flex-none justify-end">
      <AddPaymentMethodButton />
    </div>
  </div>);
}

export default PaymentDetails;