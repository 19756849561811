import { FunctionComponent, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { RadioGroup, Typography } from "@destination/components";
interface Props {
  selectedPrice: number;
  currency: string;
  yearlyPrice: number | null;
  monthlyPrice: number | null;
  onChange: (value: number | null) => void;
}
interface IRadioOption {
  label: ReactNode | string;
  disabled?: boolean;
  value: string | number;
}
const ProductSubscriptionPrices: FunctionComponent<Props> = (
  { selectedPrice, currency,
    monthlyPrice,
    yearlyPrice,
    onChange }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.product" });

  const priceOptions: IRadioOption[] = [];

  if (yearlyPrice !== null) {
    priceOptions.push(generatePriceOption(yearlyPrice, 'yearly'));
  }
  if (monthlyPrice !== null) {
    priceOptions.push(generatePriceOption(monthlyPrice, 'monthly'));

    return (<RadioGroup
      direction="column"
      value={selectedPrice}
      onChange={newValue => onChange(newValue as number)}
      options={priceOptions}
    />);
  }


  function generatePriceOption(value: number, id: string): IRadioOption {
    return {
      label: <div className="flex">
        <Typography
          variant="smallTitle"
          className="font-bold"
          data-testid={`product-price-${id}-value`}>
          {t(`prices.${id}`, {
            currency: currency, value: value
          })}
        </Typography>
      </div>,
      value: value
    };
  }
}
export default ProductSubscriptionPrices;