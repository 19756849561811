import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import OrganizationUserDropDown from "@users/organizationuser/components/OrganizationUserDropDown";
import { OrganizationUser } from "@organization/models/OrganizationUser";
import EditApplicationAssociationsForm from "./EditApplicationAssociationsForm";

interface Props {
  userId?: string;
  userDisplayName?: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditApplicationUser: FunctionComponent<Props> = ({
  userId,
  userDisplayName,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "user.applications",
  });

  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    userId
  );
  const [selectedUserDisplayName, setSelectedUserDisplayName] = useState<
    string | undefined
  >(userDisplayName);

  function handleUserChange(user: OrganizationUser | undefined) {
    if (user) {
      setSelectedUserId(user.id);
      setSelectedUserDisplayName(user.displayName);
    } else {
      setSelectedUserId(undefined);
      setSelectedUserDisplayName(undefined);
    }
  }

  return (
    <div
      className="flex flex-col gap-10 h-6 min-h-min"
      data-testid="edit-application-user-content"
    >
      <div className="place-content-center">
        <OrganizationUserDropDown
          label={t("user.label")}
          selectedUserId={selectedUserId}
          disabled={selectedUserId !== undefined}
          onUserChange={handleUserChange}
        />
      </div>
      {selectedUserId && selectedUserDisplayName && (
        <EditApplicationAssociationsForm
          userId={selectedUserId}
          userDisplayName={selectedUserDisplayName}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export default EditApplicationUser;
