import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { IconButton, Tooltip } from "@destination/components";

import useDrawer from "@hooks/useDrawer";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { OrganizationUser } from "@organization/models/OrganizationUser";
import EditUserForm from "./EditUserForm";
import { useUserContext } from "@hooks/useUserContext";

interface Props {
  organizationUser: OrganizationUser;
}

const EditUserButton: FunctionComponent<Props> = ({ organizationUser }) => {
  const { user } = useUserContext();
  const { t } = useTranslation(undefined, { keyPrefix: "organization.users" });

  const { showDrawer, hideDrawer } = useDrawer();

  const editUser = (): void => {
    showDrawer({
      title: t("edit.title"),
      content: (
        <EditUserForm
          organizationUser={organizationUser}
          onCancel={hideDrawer}
          onSuccess={() => {
            hideDrawer();
          }}
        />
      ),
    });
  };

  if (!user.isAdministrator) return null;

  return (
    <Tooltip message={t("edit.tooltip")}>
      <IconButton
        onClick={editUser}
        data-testid={`edit-user-button-${organizationUser.id}`}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditUserButton;
