import { useMemo } from "react";

import useSWR from "swr";

import { useUserContext } from "@hooks/useUserContext";
import { fetcher } from "@utils/swrUtils";

import { UserOrganizationsResponse } from "@account/models/UserOrganizationsResponse";
import { userEndpoints } from "./endpoints";

export function useGetUserOrganizations() {
  const { getAccessToken } = useUserContext();

  const url = userEndpoints.organizations();

  const { data, error, isLoading } = useSWR<
    UserOrganizationsResponse,
    Error
  >(url, (url: string) => fetcher(url, getAccessToken));

  return useMemo(
    () => ({ organizations: data?.organizations, isLoading, error }),
    [data, isLoading, error]
  );
}
