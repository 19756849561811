import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Card } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { useGetUserOrganizations } from "@account/services/use-user-organizations";

import AccountMenuCurrentOrganizationHeader from "./AccountMenuCurrentOrganizationHeader";
import AccountMenuItems from "./AccountMenuItems";
import AccountMenuOrganizationRoleHeader from "./AccountMenuOrganizationRoleHeader";

const AccountMenuContent: FunctionComponent = () => {
  const { t: tCommon } = useTranslation();
  const { t } = useTranslation(undefined, {
    keyPrefix: "account.organizations",
  });

  const { organizations, isLoading, error } = useGetUserOrganizations();

  if (isLoading) return <LoadingComponent message={tCommon("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  if (organizations === undefined || organizations.length === 0)
    return <ErrorComponent message={t("load.none.message")} />;

  return (
    <div data-testid="account-menu-content">
      <Card className="max-w-full flex-row divide-y divide-slate-200 p-4 relative">
        <AccountMenuOrganizationRoleHeader />
        <ul>
          <AccountMenuCurrentOrganizationHeader />
          <AccountMenuItems organizations={organizations} />
        </ul>
      </Card>
    </div>
  );
};

export default AccountMenuContent;
