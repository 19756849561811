import { FunctionComponent, ReactNode, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

import { Application } from "@applications/models/Application";
import { useGetAssociatedApplications } from "@applications/services/ApplicationService";
import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import ApplicationAssociation from "./ApplicationAssociation";

interface Props {
  isDisabled?: boolean;
  onAppRoleChange: (
    applicationId: string,
    applicationName: string,
    applicationRoles: string[],
    isValid: boolean,
    errorMessage: string | undefined
  ) => void;
}
const ApplicationAssociations: FunctionComponent<Props> = ({
  isDisabled,
  onAppRoleChange,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "user.applications" });

  const { applications, isLoading, error } = useGetAssociatedApplications();

  const renderChildren = useCallback(
    (children: ReactNode): ReactNode => {
      return (
        <>
          <Typography variant="smallTitle">{t("section_title")}</Typography>
          <div className="flex flex-col gap-5">{children}</div>
        </>
      );
    },
    [t]
  );

  if (isLoading)
    return renderChildren(<LoadingComponent message={t("load.message")} />);
  if (error)
    return renderChildren(<ErrorComponent message={t("load.error.message")} />);

  if (applications!.length === 0)
    return renderChildren(<>{t("load.none.message")}</>);

  return renderChildren(
    <>
      {applications?.map((application: Application) => {
        return (
          <ApplicationAssociation
            key={"appAssoc_" + application.id}
            applicationId={application.id}
            applicationName={application.name}
            isDisabled={isDisabled}
            onAppRoleChange={onAppRoleChange}
          />
        );
      })}
    </>
  );
};

ApplicationAssociations.defaultProps = {
  isDisabled: false
}
export default ApplicationAssociations;
