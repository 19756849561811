import { toOptions } from "./options";

import { formatUnknown } from "./unknownFormatter";

export function formatDate(
  value: unknown,
  format: string,
  lng?: string
): string {
  let date: Date;
  if (value instanceof Date) {
    date = value;
  } else if (typeof value === "number") {
    date = new Date(value);
  } else if (typeof value === "string") {
    date = new Date(value);
  } else {
    return formatUnknown(value);
  }

  const options = toOptions<Intl.DateTimeFormatOptions>(format);

  return new Intl.DateTimeFormat(lng, options).format(date);
}
