const baseUrl = import.meta.env.VITE_APP_OCC_API_URL as string;

export const occApiEndPoints = {
  createACart: (userId: string, baseSite: string) =>
    `${baseUrl}/${baseSite}/users/${userId}/carts`,
  addProductToCart: (userId: string, cartId: string, baseSite: string) =>
    `${baseUrl}/${baseSite}/orgUsers/${userId}/carts/${cartId}/entries/`,
  addPaymentType: (
    userId: string,
    cartId: string,
    paymentType: string,
    baseSite: string
  ) =>
    `${baseUrl}/${baseSite}/users/${userId}/carts/${cartId}/paymenttype?paymentType=${paymentType}`,
  setCardDetails: (
    userId: string,
    cartId: string,
    cardId: string,
    baseSite: string
  ) =>
    `${baseUrl}/${baseSite}/users/${userId}/carts/${cartId}/paymentdetails?paymentDetailsId=${cardId}`,
  getAddresses: (
    b2bUnit: string,
    userId: string,
    baseSite: string,
    addressType: "billing" | "shipping"
  ) =>
    `${baseUrl}/${baseSite}/users/${userId}/orgUnits/${b2bUnit}/addresses?addressType=${addressType}`,
  addDeliveryAddress: (
    userId: string,
    cartId: string,
    addressId: string,
    baseSite: string
  ) =>
    `${baseUrl}/${baseSite}/orgUsers/${userId}/carts/${cartId}/addresses/delivery?addressId=${addressId}`,
  createOrder: (userId: string, cartId: string, baseSite: string) =>
    `${baseUrl}/${baseSite}/orgUsers/${userId}/orders?cartId=${cartId}&termsChecked=true`,
};
