import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import {
  IconButton,
  Tooltip,
  useAlert,
  useDialog,
} from "@destination/components";

import { ReactComponent as TrashIcon } from "@icons/trash.svg";
import { OrganizationUser } from "@organization/models/OrganizationUser";
import { useRemoveOrganizationUsers } from "@users/organizationuser/services/OrganizationUserService";

interface Props {
  users: OrganizationUser[];
  onSuccess: () => void;
}

const DeleteUserButton: FunctionComponent<Props> = ({ users, onSuccess }) => {
  const { trigger } = useDialog();
  const { notify } = useAlert();

  const { t } = useTranslation(undefined, { keyPrefix: "organization.users" });
  const { t: tCommon } = useTranslation();

  const { deleteUser: deleteOrgUser } = useRemoveOrganizationUsers();

  const removeUsers = async () => {
    for (const user of users) {
      const { error } = await deleteOrgUser(user.id);
      if (error) {
        notify({
          header: t("delete.error.message"),
          variant: "error",
          isDismissible: true,
          timeout: 6000,
        });
      }
      onSuccess();
    }
  };

  const handleDelete = () => {
    trigger({
      variant: "warning",
      header: t("delete.confirm.title"),
      content: t("delete.confirm.message"),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: async () => {
        await removeUsers();
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        ("");
      },
      "data-testid": "delete-user",
    });
  };

  return (
    <Tooltip message={t("delete.tooltip")}>
      <IconButton onClick={handleDelete} data-testid="delete-icon-button">
        <TrashIcon className="h-3 sm:h-4" />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteUserButton;
