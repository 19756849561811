import { createContext } from "react";

import { User } from "./models/User";

interface UserContextType {
  user: User;
  getAccessToken: () => Promise<string>;
  switchOrganization: (organizationId: string) => Promise<void>;
}

export const UserContext = createContext<UserContextType>({
  user: undefined!,
  getAccessToken: () => Promise.resolve(""),
  switchOrganization: () => Promise.resolve(),
});
