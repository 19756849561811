import { toOptions } from "./options";

import { formatUnknown } from "./unknownFormatter";

export function formatNumber(
  value: unknown,
  format: string,
  lng?: string
): string {
  let num: number | bigint;
  if (typeof value === "number") {
    num = value;
  } else if (typeof value === "bigint") {
    num = value;
  } else {
    return formatUnknown(value);
  }

  const options = toOptions<Intl.DateTimeFormatOptions>(format);

  return new Intl.NumberFormat(lng, options).format(num);
}
