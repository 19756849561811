import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { IOption, Select } from "@destination/components";

import { useInput } from "@hooks/useInput";
import { isNotEmpty } from "@validators/NotEmptyValidator";

interface Props {
  label: string;
  selectedRole: string | undefined;
  disabled?: boolean;
  onRoleChange: (
    newValue: string,
    isValid: boolean,
    errorMessage?: string
  ) => void;
}

const OrganizationRoleDropDown: FunctionComponent<Props> = ({
  label,
  selectedRole,
  disabled,
  onRoleChange,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "organization.invite.users",
  });

  const options = useMemo(() => {
    return [
      { label: "Organization Admin", value: "Organization Admin" },
      { label: "Organization User", value: "Organization User" },
    ];
  }, []);

  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  useEffect(() => {
    if (selectedRole) {
      const foundOption = options.find(
        (type) => type.value.toString() === selectedRole
      );
      setSelectedOption(foundOption ?? null);
    }
  }, [options, selectedRole]);

  const { isValid, errorMessage, valueChangeHandler } = useInput(
    [isNotEmpty(() => t("validation.string.not-empty", { fieldName: label }))],
    selectedRole
  );

  const handleChange = useCallback(
    (option: IOption) => {
      setSelectedOption(option);
      const { value, isValid } = valueChangeHandler(option.value.toString());
      if (onRoleChange) onRoleChange(value, isValid);
    },
    [valueChangeHandler, onRoleChange]
  );

  return (
    <Select
      data-testid="organization-role"
      disabled={disabled}
      label={label}
      error={!isValid}
      helperText={!isValid ? errorMessage : undefined}
      options={options}
      selected={selectedOption}
      onChange={handleChange}
    />
  );
};

export default OrganizationRoleDropDown;
