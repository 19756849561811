export class User {
  constructor(id: string, name: string, roleName: string, language: string) {
    this.id = id;
    this.name = name;
    this.roleName = roleName;
    this.language = language;
  }

  readonly id: string;
  readonly name: string;
  readonly roleName: string;
  readonly language: string;

  get isAdministrator(): boolean {
    return this.roleName === "Organization Admin";
  }
}
