import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@destination/components";
import useDrawer from "@hooks/useDrawer";
import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import { usePaymentDetails } from "@store/payments/services/PaymentService";

const AddPaymentMethodButton: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.payment-methods" });

  const { getRegistrationSession } = usePaymentDetails();
  const { showDrawer } = useDrawer();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const handleAddPaymentMethod = () => {
    setIsBusy(true);

    void getRegistrationSession()
      .then((response) => {
        if (response && !(response instanceof Error)) {
          showDrawer({
            title: "",
            content: (
              <div className="relative overflow-hidden w-full h-[500px] pt-5">
                <iframe width="100%" height="100%" src={response.paymentCardRegistrationURL} title="payment"></iframe>
              </div>
            ),
          })
        }
        setIsBusy(false);
      }
      )
  }
  return (<Button data-testid="add-payment-method-button" icon={<PlusIcon />} isLoading={isBusy} variant="primary" onClick={handleAddPaymentMethod}>
    {t('add.label')}
  </Button>);
}

export default AddPaymentMethodButton;