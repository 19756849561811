import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageContent from "@components/PageContent";
import PageTitle from "@components/PageTitle";
import ErrorComponent from "@components/ErrorComponent";

interface Props {
  message: string;
}

const ErrorPage: FunctionComponent<Props> = ({ message }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "error" });
  return (
    <PageContent>
      <PageTitle title={t("page_title")} />
      <ErrorComponent message={message} />
    </PageContent>
  );
};

export default ErrorPage;
