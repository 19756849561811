import { createContext } from "react";

import { Organization } from "./models/Organization";

interface OrganizationContextType {
  organization: Organization;
  refresh: () => void;
}

export const OrganizationContext = createContext<OrganizationContextType>({
  organization: undefined!,
  refresh: () => undefined,
});
