import { useCallback, useMemo } from "react";

import axios, { AxiosResponse } from "axios";
import { useSWRConfig } from "swr";

import { addressEndPoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { SaveAddressRequest } from "@store/addresses/models/SaveAddressRequest";

export function useAddAddress() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();
  const createAddress = useCallback(
    async (address: SaveAddressRequest) => {
      try {
        const accessToken = await getAccessToken();
        const url = addressEndPoints.createAddress();
        await axios.post<AxiosResponse>(url, address, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        await mutate(addressEndPoints.getAddresses());
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return new Error(error.message);
        }
      }
    },
    [getAccessToken, mutate]
  );

  return useMemo(
    () => ({
      createAddress,
    }),
    [createAddress]
  );
}
