import PageHelmet from "@components/PageHelmet";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import Subscriptions from "@store/subscriptions/components/Subscriptions";

const MySubscriptions: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.subscriptions" });

  return (<div data-testid="subscriptions-container">
    <PageHelmet
      title={`${t("page_title")}`}
    />
    <Subscriptions />
  </div>);
}

export default MySubscriptions;