import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Input, useAlert } from "@destination/components";

import { useUpdateOrganization } from "@organization/services/use-organization-update";
import { useInput } from "@hooks/useInput";
import { useOrganizationContext } from "@hooks/useOrganizationContext";
import { useUserContext } from "@hooks/useUserContext";
import { maxLength } from "@validators/MaxLengthValidator";
import { isNotEmpty } from "@validators/NotEmptyValidator";
import { isNotRegExMatch } from "@validators/RegExValidator";

interface Props {
  label: string;
  value: string | undefined;
}

const OrganizationName: FunctionComponent<Props> = ({ label, value }) => {
  const { t } = useTranslation();

  const { user } = useUserContext();
  const [initialValue, setInitialValue] = useState(value);
  const [isUpdating, setIsUpdating] = useState(false);
  const { notify } = useAlert();
  const { updateName } = useUpdateOrganization();
  const { refresh: refreshUserContext } = useOrganizationContext();

  const {
    value: organizationName,
    isValid,
    errorMessage,
    valueChangeHandler,
    reset,
  } = useInput(
    [
      maxLength(256, () =>
        t("validation.string.max-length", {
          fieldName: label,
          maxLength: 256,
        })
      ),
      isNotEmpty(() => t("validation.string.not-empty", { fieldName: label })),
      isNotRegExMatch(/[<>]/, () =>
        t("validation.string.illegal-characters", {
          fieldName: label,
          characters: "<, >",
        })
      ),
    ],
    initialValue
  );
  const saveChanges = async () => {
    if (!isValid || organizationName === initialValue) return;

    setIsUpdating(true);

    const { error } = await updateName(organizationName);

    if (error) {
      reset();
      notify({
        header: t("not_saved"),
        variant: "error",
        isDismissible: true,
        timeout: 6000,
      });
    } else {
      setInitialValue(organizationName);
      refreshUserContext();
    }
    setIsUpdating(false);
  };

  return (
    <Input
      data-testid={"organization-name"}
      id="name"
      type="text"
      label={label}
      error={!isValid}
      disabled={isUpdating || !user.isAdministrator}
      helperText={!isValid ? errorMessage : undefined}
      value={organizationName}
      onChange={(event) => {
        valueChangeHandler(event.target.value);
      }}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onBlur={saveChanges}
    />
  );
};

export default OrganizationName;
