import { Outlet, useNavigate } from "react-router-dom";

import { DialogProvider, Sidebar, SidebarContext } from "@destination/components";

import Header from "@components/Header/Header";
import PageContent from "@components/PageContent";
import RequireAuth from "@components/RequireAuth";
import { FooterNavigation, MenuNavigation } from "@components/navigation/NavigationMenu";
import DrawerProvider from "@hooks/useDrawer/DrawerProvider";
import OrganizationContextProvider from "@hooks/useOrganizationContext/OrganizationContextProvider";
import UserContextProvider from "@hooks/useUserContext/UserContextProvider";
import { useContext } from "react";

export const Root = () => {
  const navigate = useNavigate();
  const { isCollapsed } = useContext(SidebarContext);
  return (
    <div data-testid="page-content" className={`flex h-screen ${!isCollapsed ? 'pl-0 ml-[150px]' : 'ml-1'}`}>
      <RequireAuth>
        <UserContextProvider>
          <OrganizationContextProvider>
            <DialogProvider>
              <DrawerProvider>
                <Sidebar
                  menuItems={<MenuNavigation />}
                  footerItems={<FooterNavigation />}
                  onClickBranding={() => navigate('/')}
                />
                <PageContent>
                  <Header />
                  <Outlet />
                </PageContent>
              </DrawerProvider>
            </DialogProvider>
          </OrganizationContextProvider>
        </UserContextProvider>
      </RequireAuth>
    </div>
  );
};
