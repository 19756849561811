export function sortValues<T>(
  values: T[] | undefined,
  activeSort: string | undefined,
  sortDirection: SortDirection,
  getSortValues: (
    activeSort: string,
    a: T,
    b: T
  ) => {
    valueA: PropertyType;
    valueB: PropertyType;
  }
) {
  if (!values) return [];

  if (!activeSort) return values;

  const result = [...values].sort((a: T, b: T): number => {
    const {
      valueA,
      valueB,
    }: {
      valueA: PropertyType;
      valueB: PropertyType;
    } = getSortValues(activeSort, a, b);
    if (typeof valueA === "string" && typeof valueB === "string") {
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    }
    return 0;
  });
  if (sortDirection === "descending") return result.reverse();
  return result;
}

export function getTimeValue(date?: Date) {
  return date != null ? date.getTime() : 0;
}

export type PropertyType = string | number | undefined;
export type SortDirection = "ascending" | "descending" | undefined;
