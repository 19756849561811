import { useCallback, useMemo } from "react";

import { useSWRConfig } from "swr";

import { organizationEndpoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { putData } from "@hooks/usePutData";

export function useUpdateOrganization() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();

  const updateName = useCallback(
    async (name: string) => {
      const url = organizationEndpoints.updateOrganizationName();
      const result = await putData<{ name: string }>(getAccessToken, url, {
        name: name,
      });
      await mutate(organizationEndpoints.organizationDetails());
      return result;
    },
    [getAccessToken, mutate]
  );

  return useMemo(() => ({ updateName }), [updateName]);
}
