import { useSWRConfig } from "swr";

import { organizationUserEndpoints } from "@api/Endpoints";
import deleteData from "@hooks/useDeleteData";
import { putData } from "@hooks/usePutData";
import { useUserContext } from "@hooks/useUserContext";
import { AddUserToOrganizationResponse } from "@users/organizationuser/models/AddUserToOrganizationResponse";
import { InviteOrganizationUser } from "@users/organizationuser/models/InviteOrganizationUser";
import { UpdateUserRole } from "@users/organizationuser/models/UpdateUserRole";

export function useInviteOrganizationUser() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();
  const invite = async (inviteOrganizationUser: InviteOrganizationUser) => {
    const url = organizationUserEndpoints.addUser();
    const result = await putData<
      InviteOrganizationUser,
      AddUserToOrganizationResponse
    >(getAccessToken, url, inviteOrganizationUser);

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return { userId: result.data?.userId, error: result.error };
  };

  return { invite };
}

export function useUpdateOrganizationUser(userId: string) {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();

  const update = async (updateUserRole: UpdateUserRole) => {
    const url = organizationUserEndpoints.updateUserRole(userId);
    const result = await putData<UpdateUserRole, string>(
      getAccessToken,
      url,
      updateUserRole
    );

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return result;
  };

  return { update };
}

export function useRemoveOrganizationUsers() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();

  const deleteUser = async (userId: string) => {
    const url = organizationUserEndpoints.removeUser(userId);
    const result = await deleteData(getAccessToken, url);

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return result;
  };

  return { deleteUser };
}
