import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Checkbox,
  SearchInput,
  Table,
  TableDataCell,
  TableHeadCell,
  Tooltip,
  Typography,
} from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import PageHelmet from "@components/PageHelmet";
import { useSearchQueryString } from "@hooks/useSearchQueryString";
import { useSortQueryString } from "@hooks/useSortQueryString";
import { useUserContext } from "@hooks/useUserContext";
import { OrganizationUser } from "@organization/models/OrganizationUser";
import { filter } from "@organization/services/filter";
import { sort } from "@organization/services/sort";
import { useGetOrganizationUsers } from "@organization/services/use-organization-users";
import UserStatusIcon from "@users/components/UserStatusIcon";
import DeleteUserButton from "@users/organizationuser/components/DeleteUserButton";
import EditUserButton from "@users/organizationuser/components/EditUserButton";
import InviteUserButton from "@users/organizationuser/components/InviteUserButton";

const OrganizationUsers: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization.users" });
  const { t: tUser } = useTranslation(undefined, { keyPrefix: "user" });
  const { t: tCommon } = useTranslation();
  const { t: tOrganization } = useTranslation(undefined, {
    keyPrefix: "organization",
  });

  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedUsers, setCheckedUsers] = useState<OrganizationUser[]>([]);

  const { activeSort, sortDirection, handleSort } = useSortQueryString();

  const { organizationUsers, isLoading, error } = useGetOrganizationUsers();
  const [filteredUsers, setFilteredUsers] = useState<
    OrganizationUser[] | undefined
  >(undefined);
  const [users, setUsers] = useState<OrganizationUser[] | undefined>(undefined);

  const [allowSelection, setAllowSelection] = useState<boolean>(false);

  const { user: currentUser } = useUserContext();
  const { searchValue, handleSearch } = useSearchQueryString();

  useEffect(() => {
    setAllowSelection(currentUser.isAdministrator);
  }, [currentUser]);

  useEffect(() => {
    const users = filter(organizationUsers, searchValue);
    setFilteredUsers(users);
  }, [organizationUsers, searchValue]);

  useEffect(() => {
    const users = sort(filteredUsers, activeSort, sortDirection);
    setUsers(users);
  }, [filteredUsers, activeSort, sortDirection]);

  useEffect(() => {
    const checkedRows: OrganizationUser[] = [];
    if (users && allowSelection) {
      users.forEach((user) => {
        if (user.id !== currentUser.id) {
          const checkBox: HTMLInputElement = document.getElementById(
            user.id
          ) as HTMLInputElement;
          checkBox.checked = checkedAll;
          if (checkedAll === true) {
            checkedRows.push(user);
          }
        }
      });
    }
    setCheckedUsers(checkedRows);
    // users is intentialy kept out of the dependencies because we handle it in a specific useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedAll, currentUser, allowSelection]);

  useEffect(() => {
    setCheckedUsers((current) => {
      const checkedRows: OrganizationUser[] = [];
      if (current) {
        current.forEach((checkedUser) => {
          const userFound = users?.find((user) => user.id === checkedUser.id);
          if (userFound) checkedRows.push(checkedUser);
        });
      }
      return checkedRows;
    });
  }, [users]);

  function handleToggleSelectAll(event: ChangeEvent<HTMLInputElement>): void {
    setCheckedAll(event.target.checked);
  }

  function handleToggleSelectRow(user: OrganizationUser): void {
    setCheckedUsers(
      checkedUsers.includes(user)
        ? checkedUsers.filter((el) => el !== user)
        : [...checkedUsers, user]
    );
  }
  function getDateFormatter(dateStyle: "short" | "long") {
    return new Intl.DateTimeFormat(currentUser.language, {
      dateStyle: dateStyle,
    });
  }

  const renderSelectionColumn = (user: OrganizationUser) => {
    if (!allowSelection) return "";
    return (
      <TableDataCell className="w-1">
        <div data-testid={user.id}>
          <Checkbox
            id={user.id}
            data-testid={`checkbox-${user.id}`}
            variant="alternative"
            disabled={user.id === currentUser.id}
            label={undefined}
            onChange={() => handleToggleSelectRow(user)}
          />
        </div>
      </TableDataCell>
    );
  };
  const renderAColumn = (value: string) => {
    return <TableDataCell>{value}</TableDataCell>;
  };
  const renderStatusColumn = (state: string) => {
    return (
      <TableDataCell>
        <div className="flex gap-2">
          <UserStatusIcon state={state} />
          {t(`state.${state}`)}
        </div>
      </TableDataCell>
    );
  };
  const renderDateColumn = (date: Date) => {
    return (
      <TableDataCell>
        <Tooltip message={getDateFormatter("long").format(new Date(date))}>
          {getDateFormatter("short").format(new Date(date))}
        </Tooltip>
      </TableDataCell>
    );
  };

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (
    <div>
      <PageHelmet
        title={`${tOrganization("page_title")} - ${tOrganization(
          "users_tab.label"
        )}`}
      />
      <div className="flex gap-3 items-center mb-3">
        {checkedUsers.length > 0 ? (
          <div
            className="float-left flex items-center gap-1"
            data-testid="delete-users"
          >
            <Typography variant="smallTitle">
              {t("selected.message", { count: checkedUsers.length })}
            </Typography>
            <DeleteUserButton
              users={checkedUsers}
              onSuccess={() => {
                setCheckedUsers([]);
                setCheckedAll(false);
              }}
            />
          </div>
        ) : (
          ""
        )}
        <div className="grow"></div>
        <div className="w-[500px]">
          {filteredUsers && (
            <SearchInput
              data-testid="search-user"
              value={searchValue}
              placeholder={t("search.placeholder")}
              onChange={handleSearch}
            />
          )}
        </div>

        {currentUser.isAdministrator && <InviteUserButton />}
      </div>
      <div data-testid="organization-users-table">
        <Table className="w-full">
          <thead>
            <tr className="leading-[0px]" data-testid="thead">
              {allowSelection && (
                <TableHeadCell className="w-1">
                  <Checkbox
                    id="checkAll"
                    data-testid="checkAll"
                    variant="alternative"
                    checked={checkedAll}
                    label={undefined}
                    onChange={handleToggleSelectAll}
                  />
                </TableHeadCell>
              )}
              <TableHeadCell
                sortActive={activeSort === "name"}
                sortDirection={sortDirection}
                onSort={() => handleSort("name")}
              >
                <div data-testid="name-column">
                  {tUser("display-name.column-header")}
                </div>
              </TableHeadCell>
              <TableHeadCell
                sortActive={activeSort === "email"}
                sortDirection={sortDirection}
                onSort={() => handleSort("email")}
              >
                <div data-testid="email-column">
                  {tUser("email.column-header")}
                </div>
              </TableHeadCell>
              <TableHeadCell
                sortActive={activeSort === "role"}
                sortDirection={sortDirection}
                onSort={() => handleSort("role")}
              >
                <div data-testid="role-column">
                  {tUser("role.column-header")}
                </div>
              </TableHeadCell>
              <TableHeadCell
                sortActive={activeSort === "created-on"}
                sortDirection={sortDirection}
                onSort={() => handleSort("created-on")}
              >
                <div data-testid="createdon-column">
                  {tUser("created-on.column-header")}
                </div>
              </TableHeadCell>
              <TableHeadCell
                sortActive={activeSort === "status"}
                sortDirection={sortDirection}
                onSort={() => handleSort("status")}
              >
                <div data-testid="status-column">
                  {tUser("status.column-header")}
                </div>
              </TableHeadCell>
              <TableHeadCell>
                <div data-testid="actions-column">
                  {tCommon("actions.column-header")}
                </div>
              </TableHeadCell>
            </tr>
          </thead>

          <tbody data-testid="tbody">
            {users?.map((organizationUser) => (
              <tr key={organizationUser.id}>
                {renderSelectionColumn(organizationUser)}
                {renderAColumn(organizationUser.displayName)}
                {renderAColumn(organizationUser.emailAddress)}
                {renderAColumn(organizationUser.organizationRole)}
                {renderDateColumn(organizationUser.createdOn)}
                {renderStatusColumn(organizationUser.state)}
                <TableDataCell>
                  <div className="grid grid-cols-2">
                    <EditUserButton organizationUser={organizationUser} />
                  </div>
                </TableDataCell>
              </tr>
            ))}
          </tbody>
          <tfoot data-testid="tfoot">
            <tr>
              <td colSpan={6} className="pt-3">
                {t("count.message", { count: filteredUsers?.length })}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};

export default OrganizationUsers;
