import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

import { useMsal } from "@azure/msal-react";

import { Sidebar } from '@destination/components';
import { useUserContext } from "@hooks/useUserContext";
import { ReactComponent as AccountIcon } from "@icons/account.svg";
import { ReactComponent as ApplicationIcon } from "@icons/application.svg";
import { ReactComponent as BellIcon } from "@icons/bell.svg";
import { ReactComponent as OrganizationIcon } from "@icons/customers.svg";
import { ReactComponent as DocumentationIcon } from "@icons/documentation.svg";
import { ReactComponent as HomeIcon } from "@icons/home.svg";
import { ReactComponent as SignOutIcon } from "@icons/signOut.svg";
import { ReactComponent as StorefrontIcon } from "@icons/storefront.svg";
import { ReactComponent as SupportIcon } from "@icons/support.svg";


export const MenuNavigation = () => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const navigationItems = useMemo(() => {
    const items = [{
      icon: <HomeIcon />,
      label: t("home.nav_item.label"),
      url: "/",
      id: "home"
    },
    {
      icon: <OrganizationIcon />,
      label: t("organization.nav_item.label"),
      url: "/organization",
      id: "organization"
    },
    {
      icon: <ApplicationIcon />,
      label: t("applications.nav_item.label"),
      url: "/applications",
      id: "applications"
    }];
    if (user.isAdministrator) {
      items.push({
        icon: <StorefrontIcon />,
        label: t("store.nav_item.label"),
        url: "/store",
        id: "store"
      });
    }
    return items;
  }, [t, user]);

  return <Navigation items={navigationItems} />;
};

export const FooterNavigation = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const handleLogout = () => {
    void instance.logout();
  };
  const footerItems = [
    {
      icon: <AccountIcon />,
      label: t("account.nav_item.label"),
      url: "/account",
      id: "account"
    },
    {
      icon: <BellIcon />,
      label: t("notifications.nav_item.label"),
      url: "/notifications",
      id: "notifications"
    },
    {
      icon: <DocumentationIcon />,
      label: t("documentation.nav_item.label"),
      url: "/documentation",
      id: "documentation"
    },
    {
      icon: <SupportIcon />,
      label: t("support.nav_item.label"),
      url: "/support",
      id: "support"
    },
    {
      icon: <SignOutIcon />,
      label: t("signOut.nav_item.label"),
      url: "/signOut",
      id: "signOut",
      onMenuClick: handleLogout
    }
  ];

  return <Navigation items={footerItems} />;
};

const Navigation = ({
  items
}: {
  items: { id: string, icon: React.ReactNode; label: string; url: string, onMenuClick?: () => void }[];
}) => {

  return (
    <nav className="flex h-full flex-col justify-between">
      {items.map(({ icon, id, label, url, onMenuClick }) => (
        <NavLink key={`navigation-${label}`} to={url} data-testid={`navigation-${label}`} onClick={() => onMenuClick ? onMenuClick() : undefined}>
          {({ isActive }) => (
            <Sidebar.MenuItem
              key={`navigation-${id}`}
              icon={icon}
              label={label}
              isActive={isActive}
            />
          )}
        </NavLink>
      ))}
    </nav>
  );
};
