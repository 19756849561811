import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { ITab } from "@destination/components";

export const useTabIndex = (tabs: ITab[]) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    const tabIndex = tabs.findIndex(({ id }) =>
      pathname.toLowerCase().endsWith("/" + id.toLowerCase())
    );

    if (tabIndex === -1) {
      return setCurrentTabIndex(0);
    }

    setCurrentTabIndex(tabIndex);
  }, [tabs, pathname]);

  return {
    currentTabIndex,
  };
};
