import axios from "axios";
import { useCallback, useMemo } from "react";
import { useSWRConfig } from "swr";

import { addressEndPoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";

export function useDeleteAddress() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();
  const deleteAddress = useCallback(
    async (addressId: string) => {
      try {
        const accessToken = await getAccessToken();
        const url = addressEndPoints.deleteAddress(addressId);
        const result = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        await mutate(addressEndPoints.getAddresses());
        return result;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return new Error(error.message);
        }
      }
    },
    [getAccessToken, mutate]
  );

  return useMemo(
    () => ({
      deleteAddress,
    }),
    [deleteAddress]
  );
}
