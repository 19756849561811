import { FunctionComponent } from "react";

import LoadingComponent from "@components/LoadingComponent";
import PageContent from "@components/PageContent";

interface Props {
  message?: string;
}

const LoadingPage: FunctionComponent<Props> = ({ message }) => {
  return (
    <PageContent>
      <LoadingComponent message={message} />
    </PageContent>
  );
};

export default LoadingPage;
