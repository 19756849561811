import { userEndpoints } from "@account/services/endpoints";
import axios from "axios";
import { IdTokenHintResponse } from "../models/IdTokenHintResponse";
import { UserRoleResponse } from "../models/UserRoleResponse";

export const getUserRoleName = async (
  accessToken: string
): Promise<string | null> => {
  const url = userEndpoints.userRoleName();
  const request = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await axios.get<UserRoleResponse>(url, request);
  const role = response.data.role;

  return role ?? null;
};

export const getIdTokenHint = async (accessToken: string, organizationId: string): Promise<string | null> => {
  const url = userEndpoints.idTokenHint(organizationId);
  const request = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await axios.get<IdTokenHintResponse>(url, request);

  return response.data?.idTokenHint ?? null;
};
