import { useCallback, useMemo } from "react";

import axios, { AxiosResponse } from "axios";
import { useSWRConfig } from "swr";

import { addressEndPoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { SetDefaultBillingAddress } from "@store/addresses/models/SetDefaultBillingAddress";

export function useSetDefaultBillingAddress() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();
  const setDefaultBillingAddress = useCallback(
    async (request: SetDefaultBillingAddress) => {
      try {
        const accessToken = await getAccessToken();
        const url = addressEndPoints.setDefaultBillingAddress();
        const result = await axios.post<AxiosResponse>(url, request, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        await mutate(addressEndPoints.getAddresses());
        return result;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return new Error(error.message);
        }
      }
    },
    [getAccessToken, mutate]
  );

  return useMemo(
    () => ({
      setDefaultBillingAddress,
    }),
    [setDefaultBillingAddress]
  );
}
