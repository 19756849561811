import { useEffect, useRef, useState } from "react";

import {
  IconButton,
  Typography,
  useClickOutside,
} from "@destination/components";
import { useOrganizationContext } from "@hooks/useOrganizationContext";
import { ReactComponent as DownIcon } from "@icons/down_16.svg";
import AccountMenuContent from "./AccountMenuContent";

const AccountMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const [shouldRenderChildren, setShouldRenderChildren] = useState(false);

  const { organization: currentOrganization } = useOrganizationContext();
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => {
    handleCollapse();
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (expanded === false) {
      timer = setTimeout(() => setShouldRenderChildren(false), 400);
    } else {
      setShouldRenderChildren(true);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [expanded]);

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  const isCardExpanded = () => {
    return expanded;
  };

  const cardContent = (
    <>
      <div data-testid="current-organization" className="w-full text-right">
        <Typography>{currentOrganization.name}</Typography>
      </div>
      <div className="ml-4">
        <IconButton>
          <DownIcon
            data-testid="user-profile-toggle-icon"
            className={isCardExpanded() ? "rotate-180" : ""}
          />
        </IconButton>
      </div>
    </>
  );

  const expandableCardContent = <AccountMenuContent />;

  return (
    <div data-testid="account-menu" ref={menuRef}>
      <div className="flex items-center hover:cursor-pointer"
        onClick={isCardExpanded() ? handleCollapse : handleExpand}
      >
        {cardContent}
      </div>
      <div
        className={`${isCardExpanded()
          ? "grid-rows-[1fr] opacity-100"
          : "grid-rows-[0fr] opacity-0"
          } duration-400 mt-1 grid w-full transition-all ease-in`}
      >
        <div className="-mx-4 -mb-4 overflow-hidden px-4 pb-4">
          {shouldRenderChildren && expandableCardContent}
        </div>
      </div>
    </div>
  );
};

export default AccountMenu;
