import { FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "@destination/components";

import { useInput } from "@hooks/useInput";
import { maxLength } from "@validators/MaxLengthValidator";
import { isNotEmpty } from "@validators/NotEmptyValidator";
import { isNotRegExMatch } from "@validators/RegExValidator";

interface Props {
  id: string;
  label: string;
  value: string | undefined;
  onInputChange?: (
    newValue: string,
    isValid: boolean,
    errorMessage?: string
  ) => void;
  disabled?: boolean;
}

const DisplayName: FunctionComponent<Props> = ({
  id,
  label,
  value,
  onInputChange,
  disabled,
}) => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation();

  const initialValue = useMemo(() => value, [value]);

  const {
    value: displayName,
    isValid,
    errorMessage,
    valueChangeHandler,
  } = useInput(
    [
      maxLength(256, () =>
        tCommon("validation.string.max-length", {
          fieldName: label,
          maxLength: 256,
        })
      ),
      isNotEmpty(() => t("validation.string.not-empty", { fieldName: label })),
      isNotRegExMatch(/[<>]/, () =>
        t("validation.string.illegal-characters", {
          fieldName: label,
          characters: "<, >",
        })
      ),
    ],
    initialValue
  );

  return (
    <Input
      data-testid={id}
      type="text"
      id={id}
      label={label}
      error={!isValid}
      helperText={!isValid ? errorMessage : undefined}
      value={displayName}
      disabled={disabled}
      onChange={(event) => {
        const { value, isValid, errorMessage } = valueChangeHandler(
          event.target.value
        );
        if (onInputChange) onInputChange(value, isValid, errorMessage);
      }}
    />
  );
};

export default DisplayName;
