const baseUrl = import.meta.env.VITE_APP_OCC_API_URL as string;
export const paymentEndPoints = {
  deletePaymentDetails: (
    userId: string,
    paymentDetailsId: string,
    baseSite: string
  ) =>
    `${baseUrl}/${baseSite}/users/${userId}/paymentdetails/${paymentDetailsId}`,
  getPaymentDetails: (userId: string, baseSite: string) =>
    `${baseUrl}/${baseSite}/users/${userId}/paymentdetails`,
  getRegistrationUrl: (baseSite: string, userId: string) =>
    `${baseUrl}/${baseSite}/users/${userId}/cardregistration/getregistrationurl?commerceType=ECOMMERCE&sessionType=ONLINE`,
};
