import { FunctionComponent, ReactNode, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, RadioGroup } from "@destination/components";
import AddressComponent from "@store/checkout/components/Address";
import { Address } from "@store/checkout/models/Address";

interface Props {
  addresses: Address[] | undefined;
  selectedAddressId: string | null;
  onChange: (selectedAddress: Address | null) => void;
}

interface IRadioOption {
  label: ReactNode | string;
  disabled?: boolean;
  value: string | number;

}
const Addresses: FunctionComponent<Props> = ({ addresses, selectedAddressId, onChange }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'store.checkout.address' });

  const [addressesFiltered, setAddressesFiltered] = useState<IRadioOption[]>([]);

  const [shownAll, setShownAll] = useState<boolean>(false);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const addressesRadioOptions = addresses.map((address) => {
        return {
          label: <AddressComponent address={address} />,
          value: `address-value-${address.id}`,
        }
      });
      setAddressesFiltered(addressesRadioOptions.slice(0, 2));
    }
  }, [addresses])

  function handleShowAll() {
    if (addresses) {
      const addressesRadioOptions = addresses.map((address) => {
        return {
          label: <AddressComponent address={address} />,
          value: `address-value-${address.id}`,
        }
      });
      if (shownAll) {
        setAddressesFiltered(addressesRadioOptions.slice(0, 2));
      }
      else {
        setAddressesFiltered(addressesRadioOptions);
      }
      setShownAll((flag) => !flag);
    }
  }

  function handleChange(selectedAddressId: string) {
    if (addresses) {
      const selectedAddress = addresses.find((item) =>
        item.id === selectedAddressId.replace('address-value-', ''));
      onChange(selectedAddress ?? null);
    }
  }

  return (
    <div data-testid="addresses">
      <RadioGroup
        direction="column"
        data-testid="addresses-radio-group"
        value={selectedAddressId ? `address-value-${selectedAddressId}` : null}
        onChange={(newValue) => handleChange(newValue as string)}
        options={addressesFiltered}
      />
      {addresses && addresses.length > 1 && (
        <div className="flex flex-row-reverse">
          <Button variant="discreet" data-testid="show-hide-all-addresses" onClick={handleShowAll}>
            {shownAll ? t('hide_all.label') : t('show_all.label')}
          </Button>
        </div>
      )}

    </div>);
}

export default Addresses;