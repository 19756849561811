
import { FunctionComponent, ReactNode, useMemo } from "react";

import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { Card, RadioGroup, Typography } from "@destination/components";
import { ReactComponent as InvoiceIcon } from "@icons/invoice.svg";
import CreditCardComponent from "@store/creditcard/components/CreditCard";
import { useGetPaymentDetails } from "@store/payments/services/PaymentService";

interface Props {
  selectedValue: string | null;
  onChange: (value: string) => void;
}
interface IRadioOption {
  label: ReactNode | string;
  disabled?: boolean;
  value: string | number;
}

const PaymentMethods: FunctionComponent<Props> = ({
  selectedValue,
  onChange,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });

  const { paymentDetails, isLoading, error } = useGetPaymentDetails();
  const paymentMethods = useMemo(() => {
    const paymentMethodRadioOptions: IRadioOption[] = []
    if (paymentDetails) {
      paymentDetails.forEach((paymentDetail) => {
        paymentMethodRadioOptions.push({
          label: <Card key={paymentDetail.id}>
            <Typography variant="smallTitle">{paymentDetail.accountHolderName}</Typography>
            <CreditCardComponent paymentDetail={paymentDetail} />
          </Card>,
          value: paymentDetail.id
        })
      })
    }

    paymentMethodRadioOptions.push({
      label: <div className="flex gap-2 items-center">
        <InvoiceIcon className="h-4 sm:h-4" />
        <Typography variant="smallText">{t("payment-methods.account.label")}
        </Typography>
      </div>,
      value: "account"
    })
    return paymentMethodRadioOptions;
  }, [paymentDetails, t]);


  if (isLoading) return <LoadingComponent message={t("payment-methods.load.message")} />;

  if (error) return <ErrorComponent message={t("payment-methods.load.error.message")} />;

  return (
    <Card className="mt-5" data-testid="payment-methods">
      <Typography
        variant="smallTitle"
        className="mt-5 font-bold"
        data-testid="payment-method-label"
      >
        {t("product.payment-method.label")}
      </Typography>
      <RadioGroup
        direction="column"
        data-testid="payment-method"
        value={selectedValue}
        onChange={(newValue) => onChange(newValue as string)}
        options={paymentMethods}
      />

    </Card>
  );
};

export default PaymentMethods;
