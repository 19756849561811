import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { IOption, Select } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { OrganizationUser } from "@organization/models/OrganizationUser";
import { useGetOrganizationUsers } from "@organization/services/use-organization-users";

interface Props {
  label: string;
  selectedUserId?: string;
  disabled?: boolean;
  onUserChange: (user: OrganizationUser | undefined) => void;
}

const OrganizationUserDropDown: FunctionComponent<Props> = ({
  label,
  selectedUserId,
  disabled = false,
  onUserChange,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization.users" });

  const { organizationUsers, isLoading, error } = useGetOrganizationUsers();

  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  useEffect(() => {
    if (organizationUsers) {
      setOptions(
        organizationUsers.map(({ id, displayName }) => ({
          value: id,
          label: displayName,
        }))
      );
    }
  }, [organizationUsers]);

  useEffect(() => {
    if (options.length) {
      if (selectedUserId) {
        const foundOption = options.find(
          (option) => option.value.toString() === selectedUserId
        );
        setSelectedOption(foundOption ?? null);
      }
    }
  }, [options, selectedUserId]);

  const handleChange = (option: IOption) => {
    setSelectedOption(option);

    const organizationUser = organizationUsers?.find(
      (user) => user.id === option.value
    );

    onUserChange(organizationUser);
  };

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (
    <Select
      data-testid="organization-user-dropdown"
      disabled={disabled}
      label={label}
      options={options}
      selected={selectedOption}
      onChange={handleChange}
    />
  );
};

export default OrganizationUserDropDown;
