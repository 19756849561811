import { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const PageContent: FunctionComponent<Props> = ({ children }) => {
  return (
    <main className="flex grow flex-col pb-[53px] pt-5 pl-[70px] pr-[120px] transition-all duration-200 sm:ml-12 sm:pl-6 sm:pr-8">
      {children}
    </main>
  );
};

export default PageContent;
