import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

const defaultLanguage = "en";

const useIdToken = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const [userId, setUserId] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [language, setLanguage] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  useEffect(() => {
    const getClaimValue = (key: string): string | null => {
      const value = account?.idTokenClaims?.[key];
      if (!value) return null;
      return value as string;
    };

    let userIdClaim = null;
    let userNameClaim = null;
    let languageClaim = null;
    let organizationIdClaim = null;
    if (account !== null) {
      userIdClaim = getClaimValue("sub");
      userNameClaim = account.name ?? getClaimValue("name");
      languageClaim = getClaimValue("language") ?? defaultLanguage;
      organizationIdClaim = getClaimValue("organizationId");
    }
    setUserId(userIdClaim);
    setUserName(userNameClaim);
    setLanguage(languageClaim);
    setOrganizationId(organizationIdClaim);
  }, [account]);

  return { userId, userName, language, organizationId };
};

export default useIdToken;
