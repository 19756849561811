import { useMemo } from "react";
import useSWR from "swr";

import { storeEndPoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { OrderItem } from "@store/purchases/models/OrderItem";
import {
  PropertyType,
  SortDirection,
  getTimeValue,
  sortValues,
} from "@utils/sortUtil";
import { fetcher } from "@utils/swrUtils";
import { OrderItemsResponse } from "../models/OrderItemsResponse";

export function useGetOrders() {
  const { getAccessToken } = useUserContext();

  const url = storeEndPoints.getOrders();

  const { data, error, isLoading } = useSWR<OrderItemsResponse, Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({ orders: data?.orderItems, isLoading, error }),
    [data, isLoading, error]
  );
}

export function filter(
  orders: OrderItem[] | undefined,
  value: string | undefined
): OrderItem[] {
  if (!orders) return [];

  if (!value) return orders;

  value = value.toLocaleLowerCase();

  return orders.filter(
    (item) =>
      value &&
      (item.productName.toLocaleLowerCase().includes(value) ||
        item.orderId.toLocaleLowerCase().includes(value) ||
        new Date(item.orderDate).toDateString().includes(value))
  );
}

export function sort(
  orders: OrderItem[] | undefined,
  activeSort: string | undefined,
  sortDirection: SortDirection
): OrderItem[] {
  return sortValues(orders, activeSort, sortDirection, getSortValues);
}

function getSortValues(activeSort: string, a: OrderItem, b: OrderItem) {
  let valueA: PropertyType;
  let valueB: PropertyType;
  switch (activeSort) {
    case "product-name":
      valueA = a.productName;
      valueB = b.productName;
      break;
    case "purchase-amount":
      valueA = a.productTotalPrice.value;
      valueB = b.productTotalPrice.value;
      break;
    case "order-id":
      valueA = a.orderId;
      valueB = b.orderId;
      break;
    case "created-by":
      valueA = a.orderCreatedBy.name;
      valueB = b.orderCreatedBy.name;
      break;
    case "purchase-date":
      valueA = getTimeValue(new Date(a.orderDate));
      valueB = getTimeValue(new Date(b.orderDate));
      break;
  }
  return { valueA, valueB };
}
