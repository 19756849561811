import axios from "axios";

export const putData = async <TRequest = unknown, TResponse = unknown>(
  getAccessToken: () => Promise<string>,
  url: string,
  data: TRequest
) => {
  try {
    const accessToken = await getAccessToken();

    const request = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.put<TResponse>(url, data, request);
    return { data: response.data, error: undefined };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { error: error.message };
    } else if (error instanceof Error) {
      console.error("Error: %o", error.message);
      return { error: error.message };
    } else {
      console.error("Error: %o", error);
      return { error: "Unknown error" };
    }
  }
};
