import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";
import OneTimePrice from "@store/products/components/ProductOneTimePrice";
import ProductSubscriptionPrices from "@store/products/components/ProductSubscriptionPrices";
import { Price } from "@store/products/models/Price";

interface Props {
  productPrice: Price;
  selectedPrice: number;
  onChange: (value: number | null) => void;
}

const ProductPrice: FunctionComponent<Props> = ({
  productPrice,
  selectedPrice,
  onChange,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.product" });

  return (
    <div data-testid="product-price">
      <Card>
        <Typography
          variant="smallTitle"
          className="mb-5 font-bold"
          data-testid={`product-price-label`}
        >
          {t("prices.label")}
        </Typography>
        {productPrice.amount !== null && (
          <OneTimePrice
            price={productPrice.amount}
            selectedPrice={selectedPrice}
            currency={productPrice.currency}
            onChange={onChange}
          />
        )}

        {productPrice.amount === null && (
          <ProductSubscriptionPrices
            selectedPrice={selectedPrice}
            currency={productPrice.currency}
            yearlyPrice={productPrice.yearly}
            monthlyPrice={productPrice.monthly}
            onChange={onChange}
          />
        )}
      </Card>
    </div>
  );
};

export default ProductPrice;
