import { ValidationResult } from "./models/ValidationResult";

export const isRegExMatch =
  (regEx: RegExp, errorMessage: () => string) => (value: string) => {
    if (regEx.test(value)) return <ValidationResult>{ isValid: true };
    return <ValidationResult>{
      isValid: false,
      errorMessage: errorMessage(),
    };
  };
export const isNotRegExMatch =
  (regEx: RegExp, errorMessage: () => string) => (value: string) => {
    if (!regEx.test(value)) return <ValidationResult>{ isValid: true };
    return <ValidationResult>{
      isValid: false,
      errorMessage: errorMessage(),
    };
  };
