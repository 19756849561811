import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Card } from "@destination/components";

import { Application } from "@applications/models/Application";
import { useGetAssociatedApplications } from "@applications/services/ApplicationService";
import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import InviteApplicationUserButton from "@users/applicationuser/components/InviteApplicationUserButton";
import ApplicationUsers from "./ApplicationUsers";

const OrganizationApplications: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "organization.applications",
  });

  const { applications, isLoading, error } = useGetAssociatedApplications();

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (
    <div data-testid="organizationApplications">
      <div className="flex justify-end">
        <InviteApplicationUserButton />
      </div>

      {applications?.map((application: Application) => {
        return (
          <div
            className="mb-4 pt-10"
            key={`application_${application.id}`}
            data-testid={`application-${application.id}`}
          >
            <Card
              expandableCardContent={
                <div className="rounded-md bg-white p-4">
                  <ApplicationUsers application={application} />
                </div>
              }
              data-testid={`organization-application-card-${application.id}`}
            >
              {application.name}
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default OrganizationApplications;
