import PageHelmet from "@components/PageHelmet";
import PageTitle from "@components/PageTitle";
import SectionTitle from "@components/SectionTitle";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const UnAuthorizedPage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "account.unauthorized" });
  return (
    <div data-testid="unauthorized-page-content">
      <PageHelmet title={t("page-title")} />
      <PageTitle title={t("page-title")} />
      <SectionTitle title={t("message")} />
    </div>
  );
};
