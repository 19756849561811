import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Popover, Typography } from "@destination/components";
import useDrawer from "@hooks/useDrawer";
import AppLogoIcon from "@icons/appLogo.svg";
import OrderCreatedMessage from "@store/checkout/components/OrderCreatedMessage";
import OrderFailedMessage from "@store/checkout/components/OrderFailedMessage";
import { ProductCheckout } from "@store/checkout/components/ProductCheckout";
import ProductPopover from "@store/products/components/ProductPopover";
import { Product } from "@store/products/models/Product";

interface Props {
  product: Product;
}

const ProductCard: FunctionComponent<Props> = ({ product }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });

  const [orderId, setOrderId] = useState<string | undefined>(undefined);
  const [orderFailed, setOrderFailed] = useState<Error | undefined>(undefined);

  const { showDrawer, hideDrawer } = useDrawer();

  useEffect(() => {
    if (orderId) {
      hideDrawer();
      showDrawer({
        title: "",
        content: (
          <OrderCreatedMessage onFinish={() => hideDrawer()} />
        ),
      })
    }
  }, [hideDrawer, orderId, showDrawer])

  useEffect(() => {
    if (orderFailed) {
      hideDrawer();
      showDrawer({
        title: "",
        content: (
          <OrderFailedMessage
            errorMessage={orderFailed.message}
            onClose={() => hideDrawer()} />
        ),
      })
    }
  }, [hideDrawer, orderFailed, showDrawer])

  const startCheckout = (): void => {
    showDrawer({
      title: "",
      content: (
        <ProductCheckout product={product}
          onSuccess={setOrderId}
          onDiscard={hideDrawer}
          onError={setOrderFailed} />
      ),
    });
  };
  return (

    <Popover content={<ProductPopover product={product} onCheckout={startCheckout} />}
      data-testid={`product-detail-popover-${product.code}`}  >
      <div className="flex max-w-[350px] h-[80px] shadow"
        data-testid={`product-card-${product.code}`
        }>
        <div className="mt-2 ml-5 h-[32px] w-[62px]">
          <img src={product.images.logoUrl === "" || product.images.logoUrl === null ? AppLogoIcon : product.images.logoUrl} alt="logo" data-testid="product-logo" />
        </div>
        <div className="flex flex-col mt-2 ml-3 w-[170px]" data-testid="product-name-description">
          <Typography variant="description" >
            {product.name}
          </Typography>
          <Typography variant="description" className="line-clamp-2 text-gray-400/75">
            {product.description}
          </Typography>
        </div>
        <div className="m-4">

          <Button data-testid={`get-button-${product.code}`} onClick={startCheckout}>
            {t("button.get.label")}
          </Button>

        </div>

      </div >
    </Popover>
  );
}

export default ProductCard;