import { FC } from "react";

import { useTranslation } from "react-i18next";


import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import AddNewAddressButton from "@store/addresses/components/AddNewAddressButton";
import AddressComponent from "@store/addresses/components/Address";
import { Address } from "@store/addresses/models/Address";
import { useGetAddresses } from "@store/addresses/services/use-addresses";

const Addresses: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });
  const { addresses, isLoading, error } = useGetAddresses();

  if (isLoading) return <LoadingComponent message={t("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  if (!addresses || addresses.length === 0)
    return <ErrorComponent message={t("load.none.message")} />;

  return (
    <div className="flex">
      <div data-testid='addresses' className="flex flex-wrap grow w-full gap-5">
        {addresses.map((address: Address) => {
          return <AddressComponent address={address} key={address.id} />;
        }
        )} </div>
      <div className="flex-none justify-end">
        <AddNewAddressButton />
      </div>
    </div>
  );
}

export default Addresses;