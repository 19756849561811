import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { IconButton, Tooltip } from "@destination/components";

import useDrawer from "@hooks/useDrawer";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ApplicationUser } from "../models/ApplicationUser";
import EditApplicationUser from "./EditApplicationUser";
import { useUserContext } from "@hooks/useUserContext";

interface Props {
  applicationId: string,
  user: ApplicationUser;
}

const EditApplicationUserButton: FunctionComponent<Props> = ({ applicationId, user }) => {

  const { user: currentUser } = useUserContext();

  const { t } = useTranslation(undefined, { keyPrefix: "application.users" });

  const { showDrawer, hideDrawer } = useDrawer();

  const editApplicationUser = (): void => {
    showDrawer({
      title: t("edit.title"),
      content: (
        <EditApplicationUser
          userId={user.userId}
          userDisplayName={user.displayName}
          onCancel={hideDrawer}
          onSuccess={() => {
            hideDrawer();
          }}
        />
      ),
    });
  };

  if (!currentUser.isAdministrator) return null;

  return (
    <Tooltip message={t("edit.tooltip")}>
      <IconButton onClick={editApplicationUser} data-testid={`edit-user-button-${applicationId}-${user.userId}`}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditApplicationUserButton;
