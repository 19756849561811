import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

interface Props {
  message: string;
}

const ErrorComponent: FunctionComponent<Props> = ({ message }) => {
  return (
    <div className="flex w-full text-red-600">
      <Typography>{message}</Typography>
    </div>
  );
};

export default ErrorComponent;
