import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageTitle from "@components/PageTitle";

const NotificationPage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "notifications" });
  return (
    <PageTitle title={t("page_title")} />
  );
};

export default NotificationPage;
