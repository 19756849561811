import { FunctionComponent } from "react";

import { Typography } from "@destination/components";
import { useOrganizationContext } from "@hooks/useOrganizationContext";
import { ReactComponent as CheckIcon } from "@icons/check-mark.svg";
import DefaultOrganizationToggleButton from "./DefaultOrganizationToggleButton";

const AccountMenuCurrentOrganizationHeader: FunctionComponent = () => {
  const { organization: currentOrganization } = useOrganizationContext();

  return (
    <li
      data-testid="current-organization-header"
      key={currentOrganization.id}
      className="flex flex-row gap-2 group/item py-1 items-center bg-white hover:bg-gray-100 cursor-pointer"
    >
      {currentOrganization.id && <CheckIcon data-testid="current-organization-check" className="max-w-none my-3 h-3 sm:h-4 hover:min-w-0" />}
      <Typography variant="description" className="grow">
        {currentOrganization.name}
      </Typography>
      <div className="float-right">
        <DefaultOrganizationToggleButton organization={currentOrganization} />
      </div>
    </li>
  );
};

export default AccountMenuCurrentOrganizationHeader;
