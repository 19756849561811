import { FunctionComponent } from "react";

import PageTitle from "@components/PageTitle";
import { useTranslation } from "react-i18next";
import StoreTabMenu from "@store/products/components/StoreTabMenu";


const StoreHomePage: FunctionComponent = () => {

  const { t } = useTranslation(undefined, { keyPrefix: "store" });

  return (
    <div>
      <PageTitle title={t("page-title")} />
      <div className="pt-8">
        <StoreTabMenu />
      </div>
    </div>
  );
};

export default StoreHomePage;