import { useMemo } from "react";

import useSWR from "swr";

import { organizationUserEndpoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";
import { OrganizationUsersResponse } from "@organization/models/OrganizationUsersResponse";
import { fetcher } from "@utils/swrUtils";

export function useGetOrganizationUsers() {
  const { getAccessToken } = useUserContext();

  const url = organizationUserEndpoints.organizationUsers();

  const { data, error, isLoading } = useSWR<OrganizationUsersResponse, Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({ organizationUsers: data?.users, isLoading, error }),
    [data, isLoading, error]
  );
}
